import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import dragIcon from '../../assets/icon-reorder.svg';
import { DashboardState } from '../../models';
import { FRATQuestion, FRATQuestionCategory } from '../../models/frats';

interface FRATItemDraggableProps {
  item: FRATQuestion | FRATQuestionCategory;
  category?: string;
}

const ItemWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 2fr 2fr 2fr 2fr;
  align-items: center;
`;

const ItemTitleSpan = styled.span`
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledSpan = styled.span`
  color: #646464;
`;

const FRATItemDraggable: React.FC<FRATItemDraggableProps> = ({ item, category }) => {
  const {
    userSettings: { dateFormat },
  } = useSelector((state: DashboardState) => state);

  const isQuestion = (checkItem: FRATQuestion | FRATQuestionCategory): checkItem is FRATQuestion => {
    return (checkItem as FRATQuestion).question !== undefined;
  };

  return (
    <ItemWrapper data-testid="FRATItemDraggable--Wrapper" key={item?.id}>
      <img src={dragIcon} alt="drag icon" data-testid="FRATItemDraggable--DragIcon" />
      <ItemTitleSpan data-testid="FRATItemDraggable--ItemTitle">
        {isQuestion(item) ? item.question : item.category}
      </ItemTitleSpan>
      <StyledSpan data-testid="FRATItemDraggable--Options">
        {isQuestion(item)
          ? item?.flight_risk_assessment_options?.length
          : item?.flight_risk_assessment_questions?.length}
      </StyledSpan>
      <StyledSpan data-testid="FRATItemDraggable--Category">{isQuestion(item) ? category : item?.category}</StyledSpan>
      <StyledSpan data-testid="FRATItemDraggable--DateAdded">
        {item?.created_at ? moment(item?.created_at).format(dateFormat) : '-'}
      </StyledSpan>
      <StyledSpan data-testid="FRATItemDraggable--DateModified">
        {item?.updated_at ? moment(item?.updated_at).format(dateFormat) : '-'}
      </StyledSpan>
    </ItemWrapper>
  );
};

FRATItemDraggable.defaultProps = {
  category: '',
};

export default FRATItemDraggable;
