import _ from 'lodash';

export type DataValue = string | number | boolean | object;

const updateLocalDataObject = (changes: {
  value: DataValue;
  key: string;
}[], dataObject: any) => {
  if (!dataObject) {
    return dataObject;
  }

  const newDataObject = _.cloneDeep(dataObject);
  changes.forEach(({ value, key }) => {
    if (typeof key !== 'string') {
      return;
    }
    const keys = key.split('.');
    let currentObject = newDataObject;
    for (let i = 0; i < keys.length - 1; i += 1) {
      const currentKey = keys[i];
      if (!currentObject[currentKey] || typeof currentObject[currentKey] !== 'object') {
        currentObject[currentKey] = {};
      }
      currentObject = currentObject[currentKey];
    }

    const lastKey = keys[keys.length - 1];
    currentObject[lastKey] = value;
  });
  return newDataObject;
};

export default updateLocalDataObject;
