import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../../models';
import { Defect } from '../../../models/defects';
import { DataValue } from '../../../utils/updateLocalDataObject';
import TFInput from '../../TFInput/TFInput';
import TFTooltip from '../../TFTooltip/TFTooltip';
import FlexWrapper from './FlexWrapper';
import Label from './Label';

interface RectificationLimitsProps {
  defect: Defect | null;
  updateDefectData: (changes: { value: DataValue; key: string }[]) => void;
}

const BorderLine = styled.div`
  width: 640px;
  height: 1px;
  background-color: rgba(36, 45, 65, 0.05);
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}` : '')};
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
`;

const RectificationLimits: React.FC<RectificationLimitsProps> = ({ defect, updateDefectData }) => {
  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();

  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const [flightSecondsLimit, setFlightSecondsLimit] = useState(null);
  const [apuSecondsLimit, setApuSecondsLimit] = useState(null);
  const [flightDaysLimit, setFlightDaysLimit] = useState(null);
  const [secondsLimit, setSecondsLimit] = useState(null);
  const [calendarDaysLimit, setCalendarDaysLimit] = useState(null);
  const [cyclesLimit, setCyclesLimit] = useState(null);
  const [flightsLimit, setFlightsLimit] = useState(null);
  const [otherLimit, setOtherLimit] = useState(null);

  useEffect(() => {
    if (defect) {
      setFlightSecondsLimit(defect.flight_seconds_limit);
      setApuSecondsLimit(defect.apu_seconds_limit);
      setFlightDaysLimit(defect.flight_days_limit);
      setSecondsLimit(defect.seconds_limit);
      setCalendarDaysLimit(defect.calendar_days_limit);
      setCyclesLimit(defect.cycles_limit);
      setFlightsLimit(defect.flights_limit);
      setOtherLimit(defect.other_limit);
    }
  }, [defect]);

  const apuInstalled = aircraftMap.get(id)?.apu_installed;

  return (
    <div id="rectificationLimits">
      <BorderLine marginTop="20px" />
      <FlexWrapper column marginTop={20}>
        <FlexWrapper>
          <Label fontWeight={500} marginBottom={15} marginRight={5}>
            {formatMessage({ id: 'title.enterRectificationIntervals' })}
          </Label>
          <TFTooltip>
            <span>{formatMessage({ id: 'text.allowedRectificationIntervals' })}</span>
          </TFTooltip>
        </FlexWrapper>
        <Label color="rgba(36, 45, 65, 0.7)" marginBottom={20}>
          {formatMessage({ id: 'text.pleaseEnterRectificationInterval' })}
        </Label>
        <GridLayout>
          <TFInput
            id="flightHoursLimit"
            onChange={(e): void =>
              updateDefectData([{ value: parseInt(e.target.value, 10) * 3600, key: 'flight_seconds_limit' }])}
            label={formatMessage({ id: 'form.labels.flightHourLimit' })}
            value={flightSecondsLimit ? (flightSecondsLimit / 3600).toString() : ''}
          />
          {apuInstalled ? (
            <TFInput
              id="APUHoursLimit"
              onChange={(e): void =>
                updateDefectData([{ value: parseInt(e.target.value, 10) * 3600, key: 'apu_seconds_limit' }])}
              label={formatMessage({ id: 'form.labels.apuHoursLimit' })}
              value={apuSecondsLimit ? (apuSecondsLimit / 3600).toString() : ''}
            />
          ) : null}
          <TFInput
            id="flightDaysLimit"
            onChange={(e): void =>
              updateDefectData([{ value: parseInt(e.target.value, 10), key: 'flight_days_limit' }])}
            label={formatMessage({ id: 'form.labels.flightDaysLimit' })}
            value={flightDaysLimit || ''}
          />
          <div />
          <TFInput
            id="secondsLimit"
            onChange={(e): void =>
              updateDefectData([{ value: parseInt(e.target.value, 10) * 3600, key: 'seconds_limit' }])}
            label={formatMessage({ id: 'form.labels.calendarHoursLimit' })}
            value={secondsLimit ? (secondsLimit / 3600).toString() : ''}
          />
          <TFInput
           id="calendarDaysLimit"
           onChange={(e): void =>
             updateDefectData([{ value: parseInt(e.target.value, 10), key: 'calendar_days_limit' }])}
           label={formatMessage({ id: 'form.labels.calendarDaysLimit' })}
           value={calendarDaysLimit || ''}
         />
          <TFInput
            id="cyclesLimit"
            onChange={(e): void =>
              updateDefectData([{ value: parseInt(e.target.value, 10), key: 'cycles_limit' }])}
            label={formatMessage({ id: 'form.labels.cyclesLimit' })}
            value={cyclesLimit || ''}
          />
          <div />
         <TFInput
          id="flightLimit"
          onChange={(e): void =>
            updateDefectData([{ value: parseInt(e.target.value, 10), key: 'flights_limit' }])}
          label={formatMessage({ id: 'form.labels.flightLimit' })}
          value={flightsLimit || ''}
        />
        <TFInput
          id="otherLimit"
          onChange={(e): void =>
            updateDefectData([{ value: e.target.value, key: 'other_limit' }])}
          label={formatMessage({ id: 'form.labels.otherLimit' })}
          value={otherLimit || ''}
        />
        </GridLayout>
      </FlexWrapper>
    </div>
  );
};

export default RectificationLimits;
