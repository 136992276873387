import { Accordion } from '@arcflight/tf-component-library';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import { DataValue } from '../../utils/updateLocalDataObject';
import oilIcon from '../../assets/icon-card-uplift-general.svg';
import useGetAircraftStandardFieldProperty from '../../services/hooks/aircraft/useGetAircraftStandardFieldProperty';
import errorIcon from '../../assets/login-error.svg';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import { checkNumberIsTwoDecimalPlaces, combineFluidUplifts } from '../../utils/utils';
import {
  ContentWrapper,
  DisplayText,
  Header,
  PartsDiv,
  RowHeader,
  StyledInput,
  StyledUnit,
  TitleWrapper,
  UnitWrapper,
} from './TripUpdateAirframeSection';

interface TripUpdateOilSectionProps {
  trip: any;
  updateTripUpdateData: (changes: { value: DataValue; key: string }[]) => void;
}

const OuterWrapper = styled.div`
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  padding: ${({ isMobile }): string => (isMobile ? '16px 20px' : '16px 0 20px 56px')};
`;

const LogHeader = styled.span`
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  color: #242d41;
`;

const OilLogWrapper = styled.div`
  border-bottom: ${({ border }): string => (border ? '1px solid rgba(36, 45, 65, 0.05)' : 'none')};
  margin-bottom: ${({ border }): string => (border ? '20px' : '0')};
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
`;

const UnitSelectionWrapper = styled.div`
  grid-column: ${({ editable }): string => (editable ? 'span 3' : 'auto')};
`;

const ErrorWrapper = styled.div`
  font-size: '16px';
  color: '#ac1717';
  margin-top: 4px;
  img {
    margin-right: 4px;
  }
`;

const TripUpdateOilSection: React.FC<TripUpdateOilSectionProps> = ({ trip, updateTripUpdateData }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const [oilUplifts, setOilUplifts] = useState(null);
  const [numberOfParts, setNumberOfParts] = useState(0);
  const [errorMap, setErrorMap] = useState(new Map());

  const engineCount = aircraftMap.get(id)?.aircraft_type?.engine_count;
  const apuInstalled = aircraftMap.get(id)?.apu_installed;
  const showAPUOilUplifts = useGetAircraftStandardFieldProperty<boolean>('apu_oil', id, 'enabled') !== false;
  const isMobile = window.innerWidth < 451;

  const handleInputChange = (value: number, key: string, destinationNumber: number): void => {
    if (checkNumberIsTwoDecimalPlaces(value.toString())) {
      errorMap.delete(`${key}${destinationNumber}`);
      setErrorMap(errorMap);
    } else {
      errorMap.set(`${key}${destinationNumber}`, true);
      setErrorMap(errorMap);
    }
    const foundIndex = oilUplifts?.findIndex((log) => log?.fluid_destination_number === destinationNumber);
    oilUplifts[foundIndex] = { ...oilUplifts[foundIndex], [key]: value };
    const existingFluidUplifts = trip?.fluid_uplifts_attributes || trip?.fluid_uplifts;
    const newFluidUplifts = combineFluidUplifts(oilUplifts, existingFluidUplifts);
    updateTripUpdateData([{ value: newFluidUplifts, key: 'fluid_uplifts_attributes' }]);
  };

  const handleOilUnitChange = (value: string, index: number): void => {
    const foundIndex = oilUplifts?.findIndex((log) => log?.fluid_destination_number === index);
    oilUplifts[foundIndex] = { ...oilUplifts[foundIndex], uplift_unit: value };
    const existingFluidUplifts = trip?.fluid_uplifts_attributes || trip?.fluid_uplifts;
    const newFluidArray = combineFluidUplifts(oilUplifts, existingFluidUplifts);
    updateTripUpdateData([{ value: newFluidArray, key: 'fluid_uplifts_attributes' }]);
  };

  const buildOilLogs = (): ReactElement[] => {
    const oilLogArray = oilUplifts?.map((log, i) => {
      if (log?.fluid_destination === 'apu' && !showAPUOilUplifts) return null;
      let title = log?.fluid_destination;
      if (title === 'apu') title = 'APU';
      return (
        <OilLogWrapper
          key={`${title}${log?.fluid_destination_number}-${log?.id}`}
          border={i + 1 !== oilUplifts?.length}
        >
          <LogHeader>{`${title} ${log?.fluid_destination_number || ''}`}</LogHeader>
          <ContentWrapper
            noBorder
            columns="1fr 1fr 1fr 1fr"
            padding={i + 1 === oilUplifts?.length ? '16px 0 0 0' : '16px 0'}
            isMobile={isMobile}
          >
            <div>
              <Header editable={mode !== 'view'}>Initial</Header>
              <UnitWrapper>
                {mode === 'view' ? (
                  <DisplayText>{log?.initial_value || '-'}</DisplayText>
                ) : (
                  <StyledInput
                    placeholder="-"
                    width="96px"
                    value={log?.initial_value || undefined}
                    step={0.1}
                    onChange={(e): void =>
                      handleInputChange(parseFloat(e.target.value), 'initial_value', log.fluid_destination_number)
                    }
                    type="number"
                  />
                )}
                <StyledUnit edit={mode !== 'view'}>{log?.uplift_unit}</StyledUnit>
              </UnitWrapper>
              {errorMap.get(`initial_value${log?.fluid_destination_number}`) ? (
                <ErrorWrapper>
                  <img src={errorIcon} alt="error" />
                  Value must be to 2 decimal places
                </ErrorWrapper>
              ) : null}
            </div>
            <div>
              <Header editable={mode !== 'view'}>Uplift</Header>
              <UnitWrapper>
                {mode === 'view' ? (
                  <DisplayText>{log?.uplift || '-'}</DisplayText>
                ) : (
                  <StyledInput
                    placeholder="-"
                    width="96px"
                    value={log?.uplift || undefined}
                    step={0.1}
                    onChange={(e): void =>
                      handleInputChange(parseFloat(e.target.value), 'uplift', log.fluid_destination_number)
                    }
                    type="number"
                  />
                )}
                <StyledUnit edit={mode !== 'view'}>{log?.uplift_unit}</StyledUnit>
              </UnitWrapper>
              {errorMap.get(`uplift${log?.fluid_destination_number}`) ? (
                <ErrorWrapper>
                  <img src={errorIcon} alt="error" />
                  Value must be to 2 decimal places
                </ErrorWrapper>
              ) : null}
            </div>
            <div>
              <Header editable={mode !== 'view'}>Carried forward</Header>
              <UnitWrapper>
                {mode === 'view' ? (
                  <DisplayText>{log?.carried_forwards || '-'}</DisplayText>
                ) : (
                  <StyledInput
                    placeholder="-"
                    width="96px"
                    value={log?.carried_forwards || undefined}
                    step={0.1}
                    onChange={(e): void =>
                      handleInputChange(parseFloat(e.target.value), 'carried_forwards', log.fluid_destination_number)
                    }
                    type="number"
                  />
                )}
                <StyledUnit edit={mode !== 'view'}>{log?.uplift_unit}</StyledUnit>
              </UnitWrapper>
              {errorMap.get(`carried_forwards${log?.fluid_destination_number}`) ? (
                <ErrorWrapper>
                  <img src={errorIcon} alt="error" />
                  Value must be to 2 decimal places
                </ErrorWrapper>
              ) : null}
            </div>
            <UnitSelectionWrapper editable={mode !== 'view'}>
              <Header editable={mode !== 'view'}>Unit</Header>
              {mode === 'view' ? (
                <DisplayText>{log?.uplift_unit}</DisplayText>
              ) : (
                <RadioWrapper>
                  <RadioInputButton
                    text="ml"
                    active={log?.uplift_unit === 'ml'}
                    marginRight="8px"
                    onClick={(): void => handleOilUnitChange('ml', log?.fluid_destination_number)}
                  />
                  <RadioInputButton
                    text="qt"
                    active={log?.uplift_unit === 'qt'}
                    marginRight="8px"
                    onClick={(): void => handleOilUnitChange('qt', log?.fluid_destination_number)}
                  />
                  <RadioInputButton
                    text="usg"
                    active={log?.uplift_unit === 'usg'}
                    marginRight="8px"
                    onClick={(): void => handleOilUnitChange('usg', log?.fluid_destination_number)}
                  />
                </RadioWrapper>
              )}
            </UnitSelectionWrapper>
          </ContentWrapper>
        </OilLogWrapper>
      );
    });
    return oilLogArray;
  };

  useEffect(() => {
    const createOilUplifts = () => {
      const newArray = [];
      for (let index = 1; index <= engineCount; index += 1) {
        newArray.push({
          fluid_destination: 'engine',
          fluid_destination_number: index,
          id: '',
          uplift: '',
          uplift_unit: aircraftMap.get(id)?.oil_unit,
          initial_value: '',
          carried_forwards: '',
          fluid_type: 'oil',
        });
      }
      if (apuInstalled && showAPUOilUplifts) {
        newArray.push({
          fluid_destination: 'apu',
          fluid_destination_number: null,
          id: '',
          uplift: '',
          uplift_unit: aircraftMap.get(id)?.oil_unit,
          initial_value: '',
          carried_forwards: '',
          fluid_type: 'oil',
        });
      }
      return newArray;
    };

    const filterForEngineAndAPU = (uplift) => {
      return (
        uplift?.fluid_type === 'oil' &&
        (uplift?.fluid_destination === 'engine' ||
          (apuInstalled && showAPUOilUplifts && uplift?.fluid_destination === 'apu'))
      );
    };

    if (trip) {
      if (trip?.fluid_uplifts_attributes?.some(filterForEngineAndAPU)) {
        const newLogs = trip?.fluid_uplifts_attributes?.filter(filterForEngineAndAPU);
        setOilUplifts(newLogs);
        const partCount = newLogs?.length * 3;
        setNumberOfParts(partCount);
      } else if (trip?.fluid_uplifts?.some(filterForEngineAndAPU)) {
        const newLogs = trip?.fluid_uplifts?.filter(filterForEngineAndAPU) || [];
        setOilUplifts(newLogs);
        const partCount = newLogs?.length * 3;
        setNumberOfParts(partCount);
      } else {
        const newLogs = createOilUplifts();
        setOilUplifts(newLogs);
        const partCount = newLogs?.length * 3;
        setNumberOfParts(partCount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engineCount, trip]);

  return (
    <Accordion
      isExpanded={mode === 'add'}
      headerContent={
        <RowHeader>
          <TitleWrapper>
            <img src={oilIcon} alt="airframe icon" />
            Oil
          </TitleWrapper>
          <PartsDiv>{`${numberOfParts} parts`}</PartsDiv>
        </RowHeader>
      }
      dash
    >
      <OuterWrapper isMobile={isMobile}>{buildOilLogs()}</OuterWrapper>
    </Accordion>
  );
};

export default TripUpdateOilSection;
