import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { updateFRAT } from '../../services/api';
import { FRAT } from '../../models/frats';

interface UpdateFRATProps {
  handeUpdateFRATOnSuccess: (data: FRAT) => void;
  handeUpdateFRATOnError: (err: Error) => void;
}

const useUpdateFRAT = ({
  handeUpdateFRATOnSuccess,
  handeUpdateFRATOnError,
}: UpdateFRATProps): UseMutationResult<FRAT, Error, { id: string; name: string; default: boolean }, unknown> => {
  const renameCategory = useMutation({
    mutationFn: (payload: { id: string; name: string; default: boolean }) => {
      return updateFRAT(payload);
    },
    onSuccess: (data) => {
      handeUpdateFRATOnSuccess(data);
    },
    onError: (err: Error) => handeUpdateFRATOnError(err),
  });

  return renameCategory;
};

export default useUpdateFRAT;
