/* eslint-disable max-len */
import { Accordion, Button } from '@arcflight/tf-component-library';
import React, { ReactElement, useEffect, useState } from 'react';
import { message } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import defaults from '../../utils/defaults';
import maintenanceIcon from '../../assets/icon-card-maintenance-warnings.svg';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import { OperatorSetting } from '../../models/userSettings';
import { DataValue } from '../../utils/updateLocalDataObject';
import TFNumberInput from '../TFNumberInput/TFNumberInput';
import timeUtils from '../../utils/timeUtils';
import AircraftSettingsCard from './AircraftSettingsCard';
import { AccordionWrapper, ItemWrapper } from './AircraftAdvancedSettings';
import { RadioWrapper } from './AircraftTripStandardFields';

interface AircraftMaintenanceSettingsProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: DataValue; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
  operatorSettings: OperatorSetting;
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 24px;
  padding: 0 16px 16px 16px;
  @media (max-width: 1720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1540px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1310px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1020px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Label = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
  color: ${({ edit }): string => (edit ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  font-weight: ${({ edit }): string => (edit ? '600' : '400')};
  line-height: ${({ edit }): string => (edit ? '1.33' : 'normal')};
  text-transform: ${({ edit }): string => (edit ? 'none' : 'uppercase')};
`;

const Text = styled.div`
  line-height: 1.43;
  color: rgba(36, 54, 65, 0.7);
  min-height: 18px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 0 16px 16px;
`;

const SaveWrapper = styled.div`
  margin-right: 16px;
`;

const AircraftMaintenanceSettings: React.FC<AircraftMaintenanceSettingsProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
  operatorSettings,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [mxThresholds, setMxThresholds] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [mxCheckOptions, setMxCheckOptions] = useState([]);

  const { formatMessage } = useIntl();

  const handleInputChange = (value: number, key: string): void => {
    let newValue = value;
    if (key === 'apu_seconds' || key === 'flight_seconds') {
      newValue = timeUtils.hoursToSeconds(value);
    }
    mxThresholds[`${key}`] = newValue;
    updateAircraftData([{ value: mxThresholds, key: 'mx_thresholds' }]);
  };

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const validateThresholds = (): boolean => {
    let error = false;
    Object.values(mxThresholds).forEach((value) => {
      if (!value) {
        error = true;
      }
    });
    return error;
  };

  const handleLocalSaveClick = (): void => {
    if (validateThresholds()) {
      message.error('All thresholds must have a value');
    } else {
      handleSaveClick();
      setEditMode(false);
    }
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  const handleMxCheckClick = (value: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    let enabledArray = newStandardFields?.mx_checks_config?.enabled_array;
    if (!enabledArray) enabledArray = [];
    if (enabledArray.indexOf(value) !== -1) {
      enabledArray.splice(enabledArray.indexOf(value), 1);
    } else {
      enabledArray.push(value);
    }
    if (!newStandardFields.mx_checks_config) newStandardFields.mx_checks_config = {};
    newStandardFields.mx_checks_config.enabled_array = enabledArray;
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  useEffect(() => {
    if (aircraft?.mx_thresholds && Object.keys(aircraft?.mx_thresholds)?.filter((key) => key !== 'checks')?.length) {
      setMxThresholds(aircraft.mx_thresholds);
    } else if (!mxThresholds) {
      setMxThresholds(defaults.defaultMXThresholds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aircraft]);

  const displayMxChecksArray = (array): string => {
    if (array) {
      const displayArray = [...array];
      if (!displayArray || displayArray.length === 0) return '-';
      const i = displayArray.indexOf('erops');
      if (i !== -1) {
        displayArray[i] = operatorSettings?.erops;
      }
      const formattedArray = displayArray.map((item) => item.replace(/_/g, ' '));
      return formattedArray.join(', ');
    }
    return '-';
  };

  const buildDynamicChecks = (): ReactElement[] => {
    return mxCheckOptions?.map((check) => {
      return (
        <RadioInputButton
          text={check.replace(/_/g, ' ')}
          active={aircraft?.standard_fields?.mx_checks_config?.enabled_array?.includes(check)}
          marginRight="8px"
          onClick={(): void => handleMxCheckClick(check)}
          checkbox
        />
      );
    });
  };

  useEffect(() => {
    if (aircraft?.mx_thresholds?.checks && Object.keys(aircraft.mx_thresholds.checks)) {
      setMxCheckOptions(Object.keys(aircraft.mx_thresholds.checks));
    } else if (aircraft?.standard_fields?.mx_checks_config?.enabled_array?.length > 0) {
      setMxCheckOptions(aircraft.standard_fields.mx_checks_config.enabled_array);
    } else if (
      !aircraft?.standard_fields?.mx_checks_config ||
      aircraft?.standard_fields?.mx_checks_config?.enabled_array?.length === 0
    ) {
      setMxCheckOptions(['preflight', 'daily', 'weekly', 'erops']);
    }
  }, [aircraft]);

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={maintenanceIcon}
            title="Maintenance warnings"
            subTitle="These settings define the point at which maintenance items will transition from green to amber, alerting users that something may be due soon."
            buttonText="Edit warnings"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
          />
        }
      >
        <StyledGrid>
          <div data-testid="AircraftMaintenanceSettings--Days">
            <Label edit={editMode}>{formatMessage({ id: 'text.days' })}</Label>
            {!editMode ? (
              <Text>{mxThresholds?.days}</Text>
            ) : (
              <TFNumberInput
                value={mxThresholds?.days}
                width="100px"
                onChange={(number): void => handleInputChange(number, 'days')}
                min={0}
              />
            )}
          </div>
          <div data-testid="AircraftMaintenanceSettings--Cycles">
            <Label edit={editMode}>{formatMessage({ id: 'text.cycles' })}</Label>
            {!editMode ? (
              <Text>{mxThresholds?.cycles}</Text>
            ) : (
              <TFNumberInput
                value={mxThresholds?.cycles}
                width="100px"
                onChange={(number): void => handleInputChange(number, 'cycles')}
                min={0}
              />
            )}
          </div>
          <div data-testid="AircraftMaintenanceSettings--Flights">
            <Label edit={editMode}>{formatMessage({ id: 'text.flights' })}</Label>
            {!editMode ? (
              <Text>{mxThresholds?.flights}</Text>
            ) : (
              <TFNumberInput
                value={mxThresholds?.flights}
                width="100px"
                onChange={(number): void => handleInputChange(number, 'flights')}
                min={0}
              />
            )}
          </div>
          <div data-testid="AircraftMaintenanceSettings--Landings">
            <Label edit={editMode}>{formatMessage({ id: 'text.landings' })}</Label>
            {!editMode ? (
              <Text>{mxThresholds?.landings}</Text>
            ) : (
              <TFNumberInput
                value={mxThresholds?.landings}
                width="100px"
                onChange={(number): void => handleInputChange(number, 'landings')}
                min={0}
              />
            )}
          </div>
          {aircraft?.apu_installed ? (
            <div data-testid="AircraftMaintenanceSettings--APUHours">
              <Label edit={editMode}>{formatMessage({ id: 'text.apuHours' })}</Label>
              {!editMode ? (
                <Text>{mxThresholds?.apu_seconds ? timeUtils.secondsToHours(mxThresholds?.apu_seconds) : ''}</Text>
              ) : (
                <TFNumberInput
                  value={mxThresholds?.apu_seconds ? timeUtils.secondsToHours(mxThresholds?.apu_seconds) : ''}
                  width="100px"
                  onChange={(number): void => handleInputChange(number, 'apu_seconds')}
                  min={0}
                />
              )}
            </div>
          ) : null}
          <div data-testid="AircraftMaintenanceSettings--FlightHours">
            <Label edit={editMode}>{formatMessage({ id: 'text.flightHours' })}</Label>
            {!editMode ? (
              <Text>{mxThresholds?.flight_seconds ? timeUtils.secondsToHours(mxThresholds?.flight_seconds) : ''}</Text>
            ) : (
              <TFNumberInput
                value={mxThresholds?.flight_seconds ? timeUtils.secondsToHours(mxThresholds?.flight_seconds) : ''}
                width="100px"
                onChange={(number): void => handleInputChange(number, 'flight_seconds')}
                min={0}
              />
            )}
          </div>
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper span="2">
            <Label edit={editMode}>{formatMessage({ id: 'text.enabledChecks' })}</Label>
            {!editMode ? (
              <Text>{displayMxChecksArray(aircraft?.standard_fields?.mx_checks_config?.enabled_array)}</Text>
            ) : (
              <RadioWrapper>{buildDynamicChecks()}</RadioWrapper>
            )}
          </ItemWrapper>
        </StyledGrid>
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button height="32px" onClick={handleLocalSaveClick}>
                Save
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              Cancel
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftMaintenanceSettings;
