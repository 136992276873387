import { useSelector } from 'react-redux';
import { DashboardState } from '../../../models';

export default function useGetAircraftStandardFieldProperty<T>(key: string, id: string, propName: string): T {
  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const foundStandardField = aircraftMap.get(id)?.standard_fields?.[key]?.[propName];
  return foundStandardField;
}
