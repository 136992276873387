import { useSelector } from 'react-redux';
import { DashboardState } from '../models';

export default function useGetAppSetting (key: string, id: string): boolean {
  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const foundAppSetting = aircraftMap.get(id)?.app_settings?.[key];
  return foundAppSetting;
}
