import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { createFRATCategory } from '../../services/api';
import { FRATQuestionCategory } from '../../models/frats';

interface AddFRATCategoryProps {
  handleAddCategoryOnSuccess: (data: FRATQuestionCategory) => void;
  handleAddCategoryOnError: (err: Error) => void;
}

const useCreateFRATCategory = ({
  handleAddCategoryOnSuccess,
  handleAddCategoryOnError,
}: AddFRATCategoryProps):
UseMutationResult<FRATQuestionCategory, Error, { category: string; sequence_number: number; id: string }, unknown> => {
  const addFRATCategory = useMutation({
    mutationFn: (payload: { category: string; sequence_number: number; id: string }) => {
      return createFRATCategory(payload);
    },
    onSuccess: (data) => {
      handleAddCategoryOnSuccess(data);
    },
    onError: (err: Error) => handleAddCategoryOnError(err),
  });

  return addFRATCategory;
};

export default useCreateFRATCategory;
