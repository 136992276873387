import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { deleteFRATCategory } from '../../services/api';

interface DeleteFRATCategoryProps {
  handleDeleteCategoryOnSuccess: () => void;
  handleDeleteCategoryOnError: (err: Error) => void;
}

const useDeleteFRATCategory = ({
  handleDeleteCategoryOnSuccess,
  handleDeleteCategoryOnError,
}: DeleteFRATCategoryProps): UseMutationResult<unknown, Error, { id: string }, unknown> => {
  const deleteCategory = useMutation({
    mutationFn: (payload: { id: string }) => {
      return deleteFRATCategory(payload);
    },
    onSuccess: () => {
      handleDeleteCategoryOnSuccess();
    },
    onError: (err: Error) => handleDeleteCategoryOnError(err),
  });

  return deleteCategory;
};

export default useDeleteFRATCategory;
