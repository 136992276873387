import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { reorderFRAT } from '../../services/api';
import { FRAT } from '../../models/frats';


interface ReorderFRATProps {
  handleReorderOnSuccess: (data: FRAT) => void;
  handleReorderOnError: (err: Error) => void;
}

const useReorderFRAT = ({
  handleReorderOnSuccess,
  handleReorderOnError,
}: ReorderFRATProps): UseMutationResult<FRAT, Error, unknown, unknown> => {
  const reorder = useMutation({
    mutationFn: (payload: {
      flight_risk_assessment_question_categories_attributes: {
        id: string;
        sequence_number: number;
        flight_risk_assessment_questions_attributes: {
          id: string;
          sequence_number: number;
          flight_risk_assessment_question_category_id: string;
        }[];
      }[];
    }) => {
      return reorderFRAT(payload);
    },
    onSuccess: (data) => {
      handleReorderOnSuccess(data);
    },
    onError: (err: Error) => handleReorderOnError(err),
  });

  return reorder;
};

export default useReorderFRAT;
