import { Button } from '@arcflight/tf-component-library';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import { DashboardState } from '../../models';
import { changeDrawerMode, changeDrawerVisibility, changeModalVisibility, revertChanges } from '../../models/drawer';
import { deleteUpdate } from '../../models/trips/actions';

const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Title = styled.div`
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
`;

const Text = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .cancelButton {
    height: 30px;
    border-radius: 15px;
    margin-left: 20px;
  }
`;

const SubmitButtonWrapper = styled.div`
  margin-right: 20px;
`;

const GlobalModalContent: React.FC = () => {
  const {
    drawer: { modalContent, closeDrawerData },
  } = useSelector((state: DashboardState) => state);

  const dispatch = useDispatch();

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(true);

  const handleDrawerClose = (): void => {
    if (closeDrawerData?.type === 'tripUpdate') {
      dispatch(deleteUpdate({ payload: { id: closeDrawerData.id } }));
    }
  };

  let saveFunction = (): void => {
    dispatch(changeModalVisibility({ payload: false }));
    dispatch(revertChanges({ payload: true }));
    dispatch(changeDrawerVisibility({ payload: false }));
    if (closeDrawerData) {
      handleDrawerClose();
    }
    dispatch(changeDrawerMode({ payload: 'view' }));
  };
  if (modalContent?.saveAction) saveFunction = modalContent?.saveAction;

  let cancelFunction = (): void => {
    dispatch(changeModalVisibility({ payload: false }));
  };
  if (modalContent?.cancelAction) cancelFunction = modalContent?.cancelAction;

  const handleDisablingSaveButton = () => {
    setIsSaveButtonDisabled(true);
  };

  const isMobile = window.innerWidth < 769;

  useEffect(() => {
    if (modalContent?.hideCancel) {
      setShowCancelButton(false);
    }
  }, [modalContent?.hideCancel]);

  return (
    <ModalWrapper>
      <Title data-testid="modal-title">{modalContent?.title || 'Are you sure you want to cancel?'}</Title>
      <Text data-testid="modal-text">
        {modalContent?.text ||
          `Are you sure you want to leave this page without saving your changes?
          If you leave before saving, your changes will be lost.`}
      </Text>
      <ButtonWrapper data-testid="modal-button-wrapper">
        <SubmitButtonWrapper>
          <Button padding="0 28px" disabled={isSaveButtonDisabled} size={ButtonSize.MEDIUM} onClick={() => {
              handleDisablingSaveButton();
              saveFunction();
            }}>
            {isMobile ? modalContent?.saveButtonText || 'Continue' : modalContent?.saveButtonText || 'Discard changes'}
          </Button>
        </SubmitButtonWrapper>
        {showCancelButton && (
          <Button padding="0 28px" size={ButtonSize.MEDIUM} primary={false} onClick={cancelFunction}>
            {modalContent?.cancelButtonText || 'Cancel'}
          </Button>
        )}
      </ButtonWrapper>
    </ModalWrapper>
  );
};

export default GlobalModalContent;
