import { Accordion } from '@arcflight/tf-component-library';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { checkNumberIsTwoDecimalPlaces, combineFluidUplifts } from '../../utils/utils';
import useGetAircraftStandardFieldProperty from '../../services/hooks/aircraft/useGetAircraftStandardFieldProperty';
import oilIcon from '../../assets/icon-card-uplift-general.svg';
import errorIcon from '../../assets/login-error.svg';
import { DashboardState } from '../../models';
import { DataValue } from '../../utils/updateLocalDataObject';
import { Flight } from '../../models/flights';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import { DisplayText, Header, StyledInput } from './FlightDrawer';

interface OilUpliftsProps {
  flight: Flight;
  updateFlightData: (changes: { value: DataValue; key: string }[]) => void;
}

const RowHeader = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 1.33;
  font-size: 12px;
  text-transform: capitalize;
  img {
    margin-right: 8px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PartsDiv = styled.div`
  margin-right: 20px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }): string => (isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr')};
  grid-template-rows: auto;
  row-gap: 20px;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? '20px' : '0')};
  padding: 16px 20px;
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
`;

const UnitSpan = styled.span`
  margin-left: 8px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
`;

const UnitSelectionWrapper = styled.div`
  grid-column: ${({ editable }): string => (editable ? 'span 3' : 'auto')};
`;

const ErrorWrapper = styled.div`
  font-size: '16px';
  color: '#ac1717';
  margin-top: 4px;
  img {
    margin-right: 4px;
  }
`;

const OilUplifts: React.FC<OilUpliftsProps> = ({ flight, updateFlightData }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
    userSettings,
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const [oilUplift, setOilUplift] = useState([]);
  const [engineCount, setEngineCount] = useState(null);
  const [errorMap, setErrorMap] = useState(new Map());

  const renameOilUplifts = useGetAircraftStandardFieldProperty<string>('oil_uplifts', id,'name_override');
  const showAPUOilUplifts = useGetAircraftStandardFieldProperty<boolean>('apu_oil', id, 'enabled') !== false;
  const apuInstalled = aircraftMap.get(id)?.apu_installed;

  const currentOperator = userSettings?.details?.operators?.find((operator) => {
    return operator.id === aircraftMap.get(id)?.operator_id;
  });

  const handleUpliftChange = (value: string, key: string): void => {
    if (checkNumberIsTwoDecimalPlaces(value)) {
      errorMap.delete(key);
      setErrorMap(errorMap);
    } else {
      errorMap.set(key, true);
      setErrorMap(errorMap);
    }
    const foundUpliftIndex = oilUplift.findIndex((item) => item.fluid_destination_number === key);
    oilUplift[foundUpliftIndex] = { ...oilUplift[foundUpliftIndex], uplift: value };
    const existingFluidUplifts = flight?.fluid_uplifts_attributes || flight?.fluid_uplifts;
    const newFluidUplifts = combineFluidUplifts(oilUplift, existingFluidUplifts);
    updateFlightData([{ value: newFluidUplifts, key: 'fluid_uplifts_attributes' }]);
  };

  const handleOilUnitChange = (value: string): void => {
    let newOilUplifts = [...oilUplift];
    newOilUplifts = newOilUplifts.map((item) => {
      return { ...item, uplift_unit: value };
    });
    const existingFluidUplifts = flight?.fluid_uplifts_attributes || flight?.fluid_uplifts;
    const newFluidUplifts = combineFluidUplifts(newOilUplifts, existingFluidUplifts);
    updateFlightData([{ value: newFluidUplifts, key: 'fluid_uplifts_attributes' }]);
  };

  useEffect(() => {
    if (id && aircraftMap.size) {
      const eCount = aircraftMap.get(id)?.aircraft_type?.engine_count;
      setEngineCount(eCount);
    }
  }, [id, aircraftMap]);

  const engineOilUplifts = (): ReactNode => {
    if (oilUplift?.length) {
      return oilUplift?.map((uplift, index) => {
        if (uplift?.fluid_destination === 'apu' && !showAPUOilUplifts) return null;
        let title = `${uplift?.fluid_destination} ${uplift?.fluid_destination_number}`;
        if (apuInstalled) title = 'APU';
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${title}-${index}`}>
            <Header editable={mode !== 'view'}>
              {currentOperator?.operator_setting[`${uplift.fluid_destination}_${uplift.fluid_destination_number}`] ||
                title.replace(/_/g, ' ')}
            </Header>
            {mode !== 'view' ? (
              <>
                <StyledInput
                  width={74}
                  value={uplift?.uplift}
                  type="number"
                  placeholder="-"
                  onChange={(e): void => handleUpliftChange(e.target.value, uplift?.fluid_destination_number)}
                />
                <UnitSpan>{uplift?.uplift_unit}</UnitSpan>
              </>
            ) : (
              <DisplayText>
                {uplift?.uplift
                  ? `${uplift?.uplift ? Math.round(uplift?.uplift * 100) / 100 : ''} ${uplift?.uplift_unit}`
                  : '-'}
              </DisplayText>
            )}
            {errorMap.has(uplift?.fluid_destination_number) ? (
              <ErrorWrapper>
                <img src={errorIcon} alt="error" />
                Value must be to 2 decimal places
              </ErrorWrapper>
            ) : null}
          </div>
        );
      });
    }
    return null;
  };

  useEffect(() => {
    const buildUplift = (passedArray) => {
      const newArray = [];
      for (let i = 1; i <= engineCount; i += 1) {
        newArray.push({
          fluid_destination: 'engine',
          fluid_destination_number: i,
          fluid_type: 'oil',
          uplift_unit: aircraftMap.get(id)?.oil_unit,
          uplift: '',
          id: '',
        });
      }
      if (apuInstalled && showAPUOilUplifts) {
        newArray.push({
          fluid_destination: 'apu',
          fluid_destination_number: null,
          fluid_type: 'oil',
          uplift_unit: aircraftMap.get(id)?.oil_unit,
          uplift: '',
          id: '',
        });
      }
      let returnArray = passedArray;
      if (passedArray?.length !== newArray?.length) {
        returnArray = newArray.map((item) => {
          if (passedArray?.fluid_destination_number === item?.fluid_destination_number) {
            return passedArray.filter(
              (passedItem) => passedItem?.fluid_destination_number === item?.fluid_destination_number,
            );
          }
          return item;
        });
      }
      return returnArray;
    };

    const filterForEngineAndAPU = (uplift) => {
      return (
        uplift?.fluid_type === 'oil' &&
        (uplift?.fluid_destination === 'engine' ||
          (apuInstalled && showAPUOilUplifts && uplift?.fluid_destination === 'apu'))
      );
    };

    if (flight && flight?.fluid_uplifts_attributes?.some(filterForEngineAndAPU)) {
      const newUplifts = flight?.fluid_uplifts_attributes?.filter(filterForEngineAndAPU);
      setOilUplift(buildUplift(newUplifts));
    } else if (flight && flight?.fluid_uplifts?.some(filterForEngineAndAPU)) {
      const newUplifts = flight?.fluid_uplifts?.filter(filterForEngineAndAPU);
      setOilUplift(buildUplift(newUplifts));
    } else {
      setOilUplift(buildUplift([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flight, engineCount]);

  return (
    <>
      <Accordion
        dash
        add
        isExpanded={mode !== 'view'}
        headerContent={
          <RowHeader>
            <TitleWrapper>
              <img src={oilIcon} alt="engine icon" />
              {renameOilUplifts || 'Oil Uplifts'}
            </TitleWrapper>
            <PartsDiv>{`${oilUplift?.length} parts`}</PartsDiv>
          </RowHeader>
        }
      >
        <ContentWrapper>
          {engineOilUplifts()}
          <UnitSelectionWrapper editable={mode !== 'view'}>
            <Header editable={mode !== 'view'}>Oil unit</Header>
            {mode === 'view' ? (
              <DisplayText>{oilUplift && oilUplift[0]?.uplift_unit}</DisplayText>
            ) : (
              <RadioWrapper>
                <RadioInputButton
                  text="ml"
                  active={
                    oilUplift?.length ? oilUplift[0]?.uplift_unit === 'ml' : aircraftMap?.get(id)?.oil_unit === 'ml'
                  }
                  marginRight="8px"
                  onClick={(): void => handleOilUnitChange('ml')}
                />
                <RadioInputButton
                  text="qt"
                  active={
                    oilUplift?.length ? oilUplift[0]?.uplift_unit === 'qt' : aircraftMap?.get(id)?.oil_unit === 'qt'
                  }
                  marginRight="8px"
                  onClick={(): void => handleOilUnitChange('qt')}
                />
                <RadioInputButton
                  text="usg"
                  active={
                    oilUplift?.length ? oilUplift[0]?.uplift_unit === 'usg' : aircraftMap?.get(id)?.oil_unit === 'usg'
                  }
                  marginRight="8px"
                  onClick={(): void => handleOilUnitChange('usg')}
                />
              </RadioWrapper>
            )}
          </UnitSelectionWrapper>
        </ContentWrapper>
      </Accordion>
    </>
  );
};

export default OilUplifts;
