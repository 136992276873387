import { FRATQuestionCategory, IndividualFRAT } from '../../models/frats';

function findCategoryIdByQuestionId(oldCategories, questionId) {
  for (let i = 0; i < oldCategories.length; i += 1) {
    const category = oldCategories[i];
    const questions = category.flight_risk_assessment_questions;
    for (let j = 0; j < questions.length; j += 1) {
      if (questions[j].id === questionId) {
        return category.id;
      }
    }
  }
  // If the question ID is not found in any category, return null
  return null;
}

const handleReorderingOfItems = ({
  manipulatedCategories,
  reoderKey,
  innerKey,
  initialData,
}: {
  manipulatedCategories: FRATQuestionCategory[];
  reoderKey: string;
  initialData: IndividualFRAT;
  innerKey?: string;
}): FRATQuestionCategory[] => {
  if (!manipulatedCategories) return null;

  const newItems = manipulatedCategories.map((item, index) => {
    const newItem = item;
    if (innerKey) {
      newItem[innerKey].map((innerItem, innerIndex) => {
        const newInnerItem = innerItem;
        newInnerItem[reoderKey] = innerIndex;
        return newInnerItem;
      });
    }

    newItem[reoderKey] = index;

    return newItem;
  });
  const itemsToReAdd = [];
  // go through initial data and see if all the questions are there.
  const result = newItems.reduce((acc, category) => {
    const matchingOldCategory = initialData.flight_risk_assessment_question_categories.find(
      (oldCategory) => oldCategory.id === category.id,
    );
    if (matchingOldCategory) {
      // given matchingOldCategory, check if all the question ids are present in the old data
      const missingQuestionIds = category.flight_risk_assessment_questions
        .filter(
          (question) =>
            !matchingOldCategory.flight_risk_assessment_questions.some((newQuestion) => newQuestion.id === question.id),
        )
        .map((question) => question.id);
      if (missingQuestionIds.length > 0) {
        // if there are missing questions in the new category, find the category they used to be in
        const oldCategories = initialData.flight_risk_assessment_question_categories;
        missingQuestionIds.forEach((missingId) => {
          const missingQuestion = category.flight_risk_assessment_questions.find(
            (question) => question.id === missingId,
          );
          const categoryId = findCategoryIdByQuestionId(oldCategories, missingId);
          itemsToReAdd.push({ categoryId, question: missingQuestion });
        });
        // Filter out the missing questions from the category
        // eslint-disable-next-line no-param-reassign
        category.flight_risk_assessment_questions = category.flight_risk_assessment_questions.filter(
          (question) => !missingQuestionIds.includes(question.id),
        );
        acc.push(category);
      } else {
        acc.push(category);
      }
    } else {
      acc.push(category);
    }
    return acc;
  }, []);

  // Re-add missing questions into the correct category
  itemsToReAdd.forEach(({ categoryId, question }) => {
    const categoryToUpdate = result.find((category) => category.id === categoryId);
    if (categoryToUpdate) {
      categoryToUpdate.flight_risk_assessment_questions.push(question);
    } else {
      // Category not found, handle this case if necessary
      console.error(`Category with ID ${categoryId} not found.`);
    }
  });

  return result;
};

export default handleReorderingOfItems;
