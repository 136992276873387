import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { createFRATQuestion } from '../../services/api';
import { FRATQuestion } from '../../models/frats';

interface AddFRATQuestionProps {
  handleAddFRATQuestionOnSuccess: (data: FRATQuestion) => void;
  handleAddFRATQuestionOnError: (err: Error) => void;
}

const useCreateFRATQuestion = ({
  handleAddFRATQuestionOnSuccess,
  handleAddFRATQuestionOnError,
}: AddFRATQuestionProps): UseMutationResult<FRATQuestion, Error, unknown, unknown> => {
  const addFRATQuestion = useMutation({
    mutationFn: (payload: {
      question: string;
      sequence_number: number;
      flight_risk_assessment_question_category_id: string;
      flight_risk_assessment_options_attributes: { option: string; sequence_number: number; weight: number }[];
    }) => {
      return createFRATQuestion(payload);
    },
    onSuccess: (data) => {
      handleAddFRATQuestionOnSuccess(data);
    },
    onError: (err: Error) => handleAddFRATQuestionOnError(err),
  });

  return addFRATQuestion;
};

export default useCreateFRATQuestion;
