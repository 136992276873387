import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import useGetEnabledModule from '../../utils/useGetEnabledModule';
import useGetAppSetting from '../../utils/useGetAppSetting';
import { ItemWrapper, Label, StyledGrid, Text } from './AircraftAdvancedSettings';
import ShowHideToggle from './ShowHideToggle';

interface TripStandardFieldsTogglesProps {
  aircraft: Aircraft | BaseAircraft;
  editMode: boolean;
  handleToggleChange: (value: boolean, key: string) => void;
  handleAppSettingChange: (value: boolean, key: string) => void;
}

const TripStandardFieldsToggles: React.FC<TripStandardFieldsTogglesProps> = ({
  aircraft,
  editMode,
  handleToggleChange,
  handleAppSettingChange,
}) => {
  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();

  const FRATModuleEnabled = useGetEnabledModule('FRAT', id);
  const FRATEnabled = useGetAppSetting('frat_enabled', id);

  return (
    <StyledGrid>
      <ItemWrapper>
        <Label edit={editMode}>Allow multiple flight days per trip</Label>
        {!editMode ? (
          <Text>{aircraft?.standard_fields?.multi_flight_dates_on_trip?.enabled !== false ? 'Show' : 'Hide'}</Text>
        ) : (
          <ShowHideToggle
            value={aircraft?.standard_fields?.multi_flight_dates_on_trip?.enabled !== false}
            handleChange={(value: boolean): void => handleToggleChange(value, 'multi_flight_dates_on_trip')}
            showOverride="Yes"
            hideOverride="No"
          />
        )}
      </ItemWrapper>
      <ItemWrapper>
        <Label edit={editMode}>Blocks times</Label>
        {!editMode ? (
          <Text>{aircraft?.standard_fields?.blocks_times?.enabled !== false ? 'Show' : 'Hide'}</Text>
        ) : (
          <ShowHideToggle
            value={aircraft?.standard_fields?.blocks_times?.enabled !== false}
            handleChange={(value: boolean): void => handleToggleChange(value, 'blocks_times')}
          />
        )}
      </ItemWrapper>
      <ItemWrapper>
        <Label edit={editMode}>Confirm aircraft with QR code</Label>
        {!editMode ? (
          <Text>{aircraft?.standard_fields?.confirm_aircraft_with_qr_code?.enabled ? 'Show' : 'Hide'}</Text>
        ) : (
          <ShowHideToggle
            value={aircraft?.standard_fields?.confirm_aircraft_with_qr_code?.enabled}
            handleChange={(value: boolean): void => handleToggleChange(value, 'confirm_aircraft_with_qr_code')}
          />
        )}
      </ItemWrapper>
      <ItemWrapper>
        <Label edit={editMode}>Journey log</Label>
        {!editMode ? (
          <Text>{aircraft?.standard_fields?.hide_journey_log?.enabled !== true ? 'Show' : 'Hide'}</Text>
        ) : (
          <ShowHideToggle
            value={aircraft?.standard_fields?.hide_journey_log?.enabled}
            handleChange={(value: boolean): void => handleToggleChange(value, 'hide_journey_log')}
            showOverride="Hide"
            hideOverride="Show"
          />
        )}
      </ItemWrapper>
      {FRATModuleEnabled ? (
        <ItemWrapper data-testid="TripStandardFieldsToggles--FratWrapper">
          <Label edit={editMode}>{formatMessage({ id: 'text.enableFrat' })}</Label>
          {!editMode ? (
            <Text data-testid="TripStandardFieldsToggles--FratText">{FRATEnabled ? 'Show' : 'Hide'}</Text>
          ) : (
            <ShowHideToggle
              value={FRATEnabled}
              handleChange={(value: boolean): void => handleAppSettingChange(value, 'frat_enabled')}
              dataTestId="FRATToggle"
            />
          )}
        </ItemWrapper>
      ) : null}
    </StyledGrid>
  );
};

export default TripStandardFieldsToggles;
