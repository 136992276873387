import React from 'react';
import styled from 'styled-components';
import dragIcon from '../../assets/icon-reorder.svg';
import TFFormLabel from '../../components/TFFormLabel/TFFormLabel';
import { Text } from '../../components/CommonStyledComponents/CommonStyledComponents';
import { FRATOption } from '../../models/frats';

interface FRATQuestionDraggableProps {
  item: FRATOption;
  index?: number;
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 5fr;
  align-items: center;
`;

const WeightWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const FRATQuestionDraggable: React.FC<FRATQuestionDraggableProps> = ({ item, index }) => {
  return (
    <StyledGrid marginBottom="16px" key={item?.id}>
      <img src={dragIcon} alt="drag" />
      <div>
        <TFFormLabel label={`Option ${index + 1}`} id={item?.id} />
        <Text>{item?.option}</Text>
      </div>
      <WeightWrapper>
        <div>
          <TFFormLabel label="Weighting" id={`Weighting--${item?.id}`} />
          <Text>{item?.weight}</Text>
        </div>
      </WeightWrapper>
    </StyledGrid>
  );
};

FRATQuestionDraggable.defaultProps = {
  index: 0,
};

export default FRATQuestionDraggable;
