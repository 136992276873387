import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Portal from '../../components/TFPortal/TFPortal';
import { DashboardState } from '../../models';

interface ColumnDropdownProps {
  visible: boolean;
  handleColumnDropdownClick: Function;
  selectedColumns: {
    title: string;
    checked: boolean;
  }[];
  buttonPosition?: {
    top?: number;
    left?: number;
  };
  handleColumnSelection: Function;
  fixedColumns: string[];
}

const InvisiBackground = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }
`;

const MenuWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 320px;
  height: auto;
  max-height: 450px;
  overflow-y: auto;
  top: ${({ buttonPosition }): string => (buttonPosition ? `${buttonPosition.top + 28}px` : '0')};
  left: ${({ buttonPosition }): string => (buttonPosition ? `${buttonPosition.left}px` : '0')};
  z-index: 10;
  padding: 24px 50px 24px 24px;
  border-radius: 4px;
  box-shadow: 0 0 10px 5px #dbe3ed;
  border: solid 1px #fff;
  background-color: #fcfcfc;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #242d41;
  margin-bottom: 24px;
`;

const StyledLabel = styled.label`
  text-transform: capitalize;
  margin-left: 8px;
  color: rgba(36, 45, 65, 0.8);
`;

const ColumnDropdown: React.FC<ColumnDropdownProps> = ({
  visible,
  handleColumnDropdownClick,
  selectedColumns,
  buttonPosition,
  handleColumnSelection,
  fixedColumns,
}) => {
  const { id } = useParams<{ id: string }>();

  const {
    aircraft: { aircraftMap },
    userSettings: { details },
  } = useSelector((state: DashboardState) => state);

  const hideFuelAdjustments = aircraftMap.get(id)?.standard_fields?.hide_fuel_uplifts_and_adjustments?.enabled;
  const hideAllFuel = aircraftMap.get(id)?.standard_fields?.hide_all_fuel?.enabled;
  const showFuelBurn = aircraftMap.get(id)?.standard_fields?.show_fuel_burn?.enabled;
  const currentOperator = details?.operators?.find((operator) => {
    return operator.id === aircraftMap.get(id)?.operator_id;
  });

  const fuelKeys = [
    'initial_fuel',
    'adjusted_initial_fuel',
    'total_fuel_truck_uplift',
    'total_aircraft_uplift',
    'fuel_adjustment',
    'fuel_adjustment_justification',
    'departure_fuel',
    'arrival_fuel',
    'planned_fuel_burn',
    'actual_fuel_burn',
  ];

  const columnOptions = selectedColumns
    .filter((item) => {
      if (fixedColumns.includes(item.title)) return null;
      if (
        (hideFuelAdjustments && (item.title === 'fuel_adjustment' || item.title === 'fuel_adjustment_justification')) ||
        item.title === 'adjusted_initial_fuel'
      )
        return null;
      if (hideAllFuel && fuelKeys.includes(item.title)) return null;
      if (!showFuelBurn && (item.title === 'planned_fuel_burn' || item.title === 'actual_fuel_burn')) return null;
      return item;
    })
    .map((column) => {
      let newTitle = column.title.replace(/_/g, ' ');
      if (column.title === 'airframe_hours_original') newTitle = 'airframe hours';
      if (column.title === 'airframe_landings') newTitle = 'total landings';
      if (column.title.endsWith('uplift')) newTitle = newTitle.replace(' uplift', '');
      if (newTitle.includes('engine')) {
        const words = newTitle.split(' ');
        newTitle = `${currentOperator.operator_setting[`${words[0]}_${words[1]}`]} ${words[2]}`;
      }
      return (
        <StyledButton key={column.title} type="button" onClick={(e): void => e.stopPropagation()}>
          <input
            type="checkbox"
            id={column.title}
            name={column.title}
            checked={column.checked}
            onChange={(): void => handleColumnSelection(column.title)}
          />
          <StyledLabel htmlFor={column.title}>{newTitle}</StyledLabel>
        </StyledButton>
      );
    });

  return (
    <>
      {visible ? (
        <Portal>
          <InvisiBackground
            onClick={(e: MouseEvent): void => {
              e.stopPropagation();
              return handleColumnDropdownClick();
            }}
          />
          <MenuWrapper buttonPosition={buttonPosition}>
            <Title>Select the columns to view</Title>
            {columnOptions}
          </MenuWrapper>
        </Portal>
      ) : null}
    </>
  );
};

ColumnDropdown.defaultProps = {
  buttonPosition: null,
};

export default ColumnDropdown;
