import { IntlFormatters } from 'react-intl';
import { intersection } from 'lodash';
import {
  UserRoles,
  PeoplePermissions,
  DashboardResource,
  UserPermission,
  FeatureFlag,
  TechLogModule,
} from '../models/userSettings';
import reports from '../assets/mainNav/icon-reports.svg';
import operations from '../assets/mainNav/icon-operations.svg';
import fleet from '../assets/mainNav/icon-fleet.svg';
import defects from '../assets/mainNav/icon-defects.svg';
import crew from '../assets/mainNav/icon-user-settings.svg';
import settings from '../assets/mainNav/icon-settings.svg';
import frat from '../assets/mainNav/icon-nav-frat.svg';
import maintenance from '../assets/mainNav/maintenance2.svg';
import { hasDashboardPermission } from '../components/_utils/AuthenticationWrapper';
import { PersonCapabilities } from '../models/people';

export interface MenuItem {
  requiredFeatureFlag?: FeatureFlag;
  exact?: boolean;
  icon?: string;
  name?: string;
  path: string;
  redirect?: string;
  authority?: UserRoles[];
  children?: MenuItem[];
  requiredResource?: DashboardResource;
  requiredPermission?: UserPermission;
  locale?: string;
  target?: string;
  featureFlag?: FeatureFlag;
  requiredModule?: TechLogModule;
}

// Menu structure. For routes, still refer to ./config/router.config.js
export default (
  formatMessage: IntlFormatters['formatMessage'],
  credentials: UserRoles[],
  peoplePermissions: PeoplePermissions[],
  featureFlags?: FeatureFlag[],
  enabledModules?: TechLogModule[],
): MenuItem[] =>
  [
    {
      icon: fleet,
      name: formatMessage({ id: 'menu.fleet' }),
      path: '/dashboard',
      authority: [UserRoles.ADMIN],
      requiredResource: DashboardResource.DASHBOARD,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: reports,
      name: formatMessage({ id: 'menu.reports' }),
      path: '/reports',
      authority: [UserRoles.ADMIN],
      children: [
        {
          exact: true,
          name: formatMessage({ id: 'menu.metrics' }),
          path: '/reports/metrics',
          requiredResource: DashboardResource.DASHBOARD,
          requiredPermission: UserPermission.READ,
          featureFlag: FeatureFlag.METRICS,
        },
        {
          exact: true,
          name: formatMessage({ id: 'menu.map' }),
          path: '/reports/map',
        },
        {
          exact: true,
          name: formatMessage({ id: 'menu.receipts' }),
          path: '/reports/receipts',
          requiredResource: DashboardResource.RECEIPT,
          requiredPermission: UserPermission.READ,
        },
      ],
    },
    {
      icon: operations,
      name: formatMessage({ id: 'menu.operations' }),
      path: '/operations',
      authority: [UserRoles.ADMIN],
      children: [
        {
          exact: true,
          name: formatMessage({ id: 'title.sectors' }),
          path: '/operations/flights',
          requiredResource: DashboardResource.TRIP,
          requiredPermission: UserPermission.READ,
        },
        {
          exact: true,
          name: formatMessage({ id: 'menu.documents' }),
          path: '/operations/documents',
          requiredResource: DashboardResource.DOCUMENT,
          requiredPermission: UserPermission.READ,
          featureFlag: FeatureFlag.DOCUMENTS,
        },
        {
          exact: true,
          name: formatMessage({ id: 'menu.Operations.Logbook' }),
          path: '/operations/logbook',
          requiredResource: DashboardResource.AIRCRAFT,
          requiredPermission: UserPermission.READ,
        },
      ],
    },
    {
      icon: defects,
      name: formatMessage({ id: 'menu.defects' }),
      path: '/defects',
      authority: [UserRoles.ADMIN],
      requiredResource: DashboardResource.DEFECT,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: maintenance,
      name: formatMessage({ id: 'menu.maintenance' }),
      path: '/maintenance',
      authority: [UserRoles.ADMIN],
      requiredResource: DashboardResource.MX_ITEM,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: crew,
      name: formatMessage({ id: 'menu.userSettings' }),
      path: '/people',
      authority: [UserRoles.ADMIN],
      exact: true,
      requiredResource: DashboardResource.PEOPLE,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: frat,
      name: formatMessage({ id: 'text.frat' }),
      path: '/frat',
      authority: [UserRoles.ADMIN],
      exact: true,
      requiredResource: DashboardResource.AIRCRAFT,
      requiredPermission: UserPermission.READ,
      featureFlag: FeatureFlag.FRAT,
      requiredModule: TechLogModule.FRAT,
    },
    {
      icon: settings,
      name: formatMessage({ id: 'menu.settings' }),
      path: '/settings',
      authority: [UserRoles.ADMIN],
      children: [
        {
          name: formatMessage({ id: 'menu.aircraft' }),
          path: '/settings/aircraft',
          exact: true,
        },
        {
          name: formatMessage({ id: 'menu.integrations' }),
          path: '/settings/integrations',
          exact: true,
          requiredResource: DashboardResource.INTEGRATION,
          requiredPermission: UserPermission.READ,
        },
        {
          name: formatMessage({ id: 'menu.operator' }),
          path: '/settings/operators',
          exact: true,
          requiredResource: DashboardResource.INTEGRATION,
          requiredPermission: UserPermission.READ,
          featureFlag: FeatureFlag.SRPVIEW,
        },
        {
          name: formatMessage({ id: 'menu.operatorSettings' }),
          path: '/settings/operator_settings',
          exact: true,
        },
      ],
    },
  ].filter((entry: MenuItem) => {
    if (entry.requiredPermission && entry.requiredResource) {
      const hasRequiredModule = entry.requiredModule
        ? Array.isArray(enabledModules) && enabledModules.includes(entry.requiredModule)
        : true;
      const hasFeatureFlag = entry.featureFlag
        ? Array.isArray(featureFlags) && featureFlags.includes(entry.featureFlag)
        : true;
      return (
        hasRequiredModule &&
        hasFeatureFlag &&
        hasDashboardPermission(peoplePermissions, entry.requiredResource, entry.requiredPermission)
      );
    }
    // TODO: turn this into a reduce function
    if (Array.isArray(entry.children)) {
      const newChildren = entry.children.filter((child) => {
        const hasRequiredCapabilities = child.path === '/settings/operators'
          ? peoplePermissions?.some(person =>
              person?.capability_keys?.some(key =>
                key === PersonCapabilities.PDF_TEMPLATE_MANAGEMENT || key === PersonCapabilities.AMEND_TEMPLATES
              )
            )
          : true;
        if (child.requiredPermission && child.requiredResource) {
          const hasRequiredModule = child.requiredModule
            ? Array.isArray(enabledModules) && enabledModules.includes(child.requiredModule)
            : true;
          const hasFeatureFlag = child.featureFlag
            ? Array.isArray(featureFlags) && featureFlags.includes(child.featureFlag)
            : true;
          return (
            hasRequiredModule &&
            hasFeatureFlag &&
            hasRequiredCapabilities &&
            hasDashboardPermission(peoplePermissions, child.requiredResource, child.requiredPermission)
          );
        }
        return hasRequiredCapabilities;
      });
      // eslint-disable-next-line no-param-reassign
      entry.children = newChildren;
      return newChildren.length;
    }
    // Simple persmission checking to determine which pages of the dashboard the user is able to view.
    return intersection(entry.authority, credentials).length > 0;
  });
