/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Modal, Button } from '@arcflight/tf-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { getDefectForDrawer } from '../../models/defects/actions';
import { DashboardState } from '../../models';
import { IntermittentFault } from '../../models/intermittentFaults';
import {
  changeDrawerMode,
  changeDrawerVisibility,
  changeModalVisibility,
  handleBackButtonClick,
  setDrawerChanges,
  revertChanges as reduxRevertChanges,
  setDrawerId,
  handleBackButtonClickWithPayload,
} from '../../models/drawer';
import useQueryFlightsForAircraft from '../../services/hooks/flights/useQueryFlightsForAircraft';
import useQueryGetMELItems from '../../services/hooks/book_items/useQueryMelItems';
import useQueryGetCDLItems from '../../services/hooks/book_items/useQueryCDLItems';
import useQueryGetNEFItems from '../../services/hooks/book_items/useQueryNEFItems';
import useQueryGetDefectForDrawer from '../../services/hooks/defects/useQueryGetDefectForDrawer';
import useMutationUpdateDefect from '../../services/hooks/defects/useMutationUpdateDefect';
import useMutationAddDefect from '../../services/hooks/defects/useMutationAddDefect';
import updateLocalDataObject from '../../utils/updateLocalDataObject';
import Loading from '../TFLoading/index';
import HistorySection from '../HistoryTable/HistorySection';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import { DefectsActionTypes, DefectType } from '../../models/defects';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import useGetAircraftStandardFieldProperty from '../../services/hooks/aircraft/useGetAircraftStandardFieldProperty';
import useGetOperatorSetting from '../../utils/useGetOperatorSetting';
import ButtonSection from './components/ButtonSection';
import SignatureSection from './components/SignatureSection';
import MELReviewActionMap from './MELReviewActionMap';
import ViewDefectDrawer from './components/ViewDefectDrawer';
import EditDefectDrawer from './components/EditDefectDrawer';
import { isCategoryRequired } from './components/utils';

interface DefectDrawerProps {
  defectId: string;
  passedFlightId?: string;
  deferDefect?: boolean;
  newFlight?: boolean;
}

const DrawerWrapper = styled.div`
  min-height: calc(100vh - 24px);
  height: 100%;
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const ModalContentWrapper = styled.div`
  height: auto;
`;

const ModalTitle = styled.div`
  font-weight: 500;
  color: #242d41;
  padding-bottom: 12px;
`;

const ModalMessage = styled.div`
  line-height: 1.29;
  color: rgba(36, 45, 65, 0.7);
  max-width: 350px;
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 28px;
`;

const SubminButtonWraper = styled.div`
  margin-right: 12px;
`;

const DefectDrawer: React.FC<DefectDrawerProps> = ({ defectId, passedFlightId, deferDefect, newFlight }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let defect = null;
  const {
    defects: { drawerDefect, defectsMap },
    aircraft: { aircraftMap },
    drawer: { mode, drawerHistory, revertChanges, passedData, editResolved, drawerId },
    userSettings,
  } = useSelector((state: DashboardState) => state);
  if (defectId) {
    defect = drawerDefect;
  } else {
    defect = null;
  }

  const [signature, setSignature] = useState('');
  const [aircraftId, setAircraftId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bookItemsLoading, setBookItemsLoading] = useState(false);
  const [confirmationChecks, setConfirmationChecks] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [defectData, setDefectData] = useState(null);
  const [originalDefectData, setOriginalDefectData] = useState(null);
  const [signOffAsEngineer, setSignOffAsEnginner] = useState(false);
  const [isPassedIntermittentFault, setIsPassedIntermittentFault] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const { data: flightsData, isLoading: isLoadingFlights } = useQueryFlightsForAircraft({
    payload: { id: aircraftId },
    refetchOnWindowFocus: false,
    staleTime: 30000,
    enabled: !!aircraftId,
  });

  const { data: melItems, isFetching: isMelItemsLoading } = useQueryGetMELItems({
    payload: { id: aircraftId },
    refetchOnWindowFocus: false,
    staleTime: 30000,
    enabled: defectData?.defect_type === DefectType.MEL && !!aircraftId,
  });

  const { data: cdlItems, isFetching: isCdlItemsLoading } = useQueryGetCDLItems({
    payload: { id: aircraftId },
    refetchOnWindowFocus: false,
    staleTime: 30000,
    enabled: defectData?.defect_type === DefectType.CDL && !!aircraftId,
  });

  const { data: nefItems, isFetching: isNefItemsLoading } = useQueryGetNEFItems({
    payload: { id: aircraftId },
    refetchOnWindowFocus: false,
    staleTime: 30000,
    enabled: defectData?.defect_type === DefectType.NEF && !!aircraftId,
  });

  const { data: queryDefectData, isFetching: defectLoading } = useQueryGetDefectForDrawer({
    id: defectId || drawerId,
    refetchOnWindowFocus: true,
  });

  const handleUpdateDefectOnSuccess = (data) => {
    dispatch(changeDrawerMode({ payload: 'view' }));
    dispatch({
      type: DefectsActionTypes.SAVE,
      payload: [data],
    })

  };

  const updateDefect = useMutationUpdateDefect({ handleUpdateDefectOnSuccess });

  const handleAddDefectOnSuccess = ({ data }) => {
    dispatch(setDrawerId({ payload: data.id }));
    dispatch({
      type: DefectsActionTypes.SAVE,
      payload: [data],
    })
    dispatch(changeDrawerMode({ payload: 'view' }));
  };

  const addDefect = useMutationAddDefect({ handleAddDefectOnSuccess });

  const chosenAircraft = aircraftMap.get(aircraftId);
  const currentLoginId = userSettings?.details?.id;
  const currentUserId =
    userSettings?.details?.people?.find((person) => person?.organisation?.id === aircraftMap.get(id)?.operator_id)?.id;

  const melNameOverride =
    useGetAircraftStandardFieldProperty<string>('deferral_type_mel', id, 'name_override')
    || formatMessage({ id: 'text.mel' });
  const cdlNameOverride =
    useGetAircraftStandardFieldProperty<string>('deferral_type_cdl', id, 'name_override')
    || formatMessage({ id: 'text.cdl' });
  const nefNameOverride =
    useGetAircraftStandardFieldProperty<string>('deferral_type_nef', id, 'name_override')
    || formatMessage({ id: 'text.nef' });
  const casNameOverride =
    useGetAircraftStandardFieldProperty<string>('deferral_type_cas', id, 'name_override')
    || formatMessage({ id: 'text.cas' });
  const otherNameOverride =
    useGetAircraftStandardFieldProperty<string>('deferral_type_other', id, 'name_override')
    || formatMessage({ id: 'text.other' });

  const ignoreEROPS = useGetAircraftStandardFieldProperty<boolean>('non_erops', id, 'enabled') || true;
  const reasonToDeferRequired = useGetAircraftStandardFieldProperty<boolean>('reason_to_defer', id, 'required')
  || false;
  const reasonToDeferAsDropdown =
    useGetAircraftStandardFieldProperty<boolean>('reason_to_defer', id, 'dropdown_enabled') || false;
  const reasonToDeferDropdownOptions =
    useGetAircraftStandardFieldProperty<string[]>('reason_to_defer', id, 'dropdown_options_selected') || [];
  const referenceRequired = useGetAircraftStandardFieldProperty<boolean>('defect_reference', id, 'required') || false;
  const ataSelectionRequired = useGetAircraftStandardFieldProperty<boolean>('ata_selection', id, 'required') || false;
  const troubleShootingStepsRequired =
    useGetAircraftStandardFieldProperty<boolean>('troubleshooting_steps', id, 'required') || false;
  const troubleShootingStepsRename =
    useGetAircraftStandardFieldProperty<string>('troubleshooting_steps', id, 'name_override')
    || 'Troubleshooting steps';
  const showRichTextMel = useGetAircraftStandardFieldProperty<boolean>('rich_text_mel', id, 'enabled');

  const deferralApprovalReferenceRename = useGetOperatorSetting('defer_approval_reference_name', id);
  const defectRename = useGetOperatorSetting('defect_name', id);
  const reportedByTypeEnabled = useGetOperatorSetting('defect_reported_by_type', id);
  const stageOfFlightEnabled = useGetOperatorSetting('stage_of_flight_enabled', id);
  const stageOfFlightText = useGetOperatorSetting('stage_of_flight_text', id);
  const eropsRename = useGetOperatorSetting('erops', id);
  const sectorsNameOverride = useGetOperatorSetting('sector_name_plural', id);
  const part145ApprovalNumberRename = useGetOperatorSetting('part_145_approval_number', id);
  const engineerRename = useGetOperatorSetting('engineer', id);
  const engineerLicenceNumberRename = useGetOperatorSetting('engineer_licence_number', id);
  const deferUsingDefault = useGetOperatorSetting('defer_using_default', id);
  const resolutionRename = useGetOperatorSetting('resolution_name', id);
  const approvalReleaseName = useGetOperatorSetting('approval_release_name', id);

  const raiseToast = (title: string, message: string, category: ToastCategories): void => {
    dispatch(
      addToast({
        payload: {
          title,
          message,
          type: ToastTypes.ERROR,
          category,
        },
      }),
    );
  };

  const usePrevious = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevDefectsMap = usePrevious(defectsMap);

  const usePreviousDefectDrawer = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevDrawerDefect = usePreviousDefectDrawer(drawerDefect);

  const handleSignature = (input: string): void => {
    setSignature(input);
  };

  const updateDefectData = (changes: { value: any; key: string }[]): void => {
    const newData = updateLocalDataObject(changes, defectData || {});
    setDefectData(newData);
    setFormChanged(true);
  };

  const resetForm = (): void => {
    setSignature('');
    setAircraftId(null);
    setConfirmationChecks([]);
    setFormChanged(false);
    if (document.getElementById('tfDrawerWrapper')) document.getElementById('tfDrawerWrapper').scrollTop = 0;
  };

  useEffect(() => {
    if (defect?.book_item) {
      const newConfirmations = [];
      let foundRect = defect?.book_item?.rectifications && defect?.book_item?.rectifications[0];
      if (defect?.rectification_id) {
        foundRect = defect?.book_item?.rectifications?.find((rec) => rec.id === defect?.rectification_id);
      }
      if (foundRect?.remarks) {
        newConfirmations.push('remarks');
      }

      if (foundRect?.placard_procedure) {
        newConfirmations.push('placards');
      }

      if (foundRect?.operational_procedure) {
        newConfirmations.push('operations');
      }

      if (foundRect?.maintenance_procedure) {
        newConfirmations.push('maintenance');
      }
      setConfirmationChecks(newConfirmations);
    }
  }, [defect]);

  const checkAllConfirmed = (payloadKeys, payload): boolean => {
    const newPayloadKeys = Object.keys(payload);
    if (editResolved || !payload.deferred) return true;
    let allChecked = true;
    if (
      (payload.defect_type === DefectType.MEL ||
        payload.defect_type === DefectType.CDL ||
        payload.defect_type === DefectType.NEF) &&
      confirmationChecks.length > 0
    ) {
      const cardsRequiringAction = [];
      MELReviewActionMap.forEach((item): void => {
        if (confirmationChecks.includes(item.check) && !newPayloadKeys.includes(item.card)) {
          raiseToast(
            formatMessage({ id: `text.${item.error}` }),
            formatMessage({ id: `text.${item.message}` }),
            ToastCategories.FLAG,
          );
          cardsRequiringAction.push(item.card);
          allChecked = false;
        }
      });
      if (cardsRequiringAction.length > 0) document.getElementById(cardsRequiringAction[0]).scrollIntoView();
    }
    return allChecked;
  };

  const hasValidCatALimits = (recTitle, payload): boolean => {
    if (!defectData?.deferred) {
      return true;
    }
    if (recTitle !== 'A') {
      return true;
    }
    if (editResolved) return true;

    const requiredCatALimitFields = [
      'flight_seconds_limit',
      'apu_seconds_limit',
      'flight_days_limit',
      'seconds_limit',
      'cycles_limit',
      'flights_limit',
      'calendar_days_limit',
      'other_limit',
    ];

    const filledFields = requiredCatALimitFields.filter((field) => payload[field]);

    if (filledFields.length === 0) {
      raiseToast(
        formatMessage({ id: 'text.categoryALimitsError' }),
        formatMessage({ id: 'text.categoryALimits' }),
        ToastCategories.FLAG,
      );
      document.getElementById('rectificationLimits').scrollIntoView();
    }

    return filledFields.length > 0;
  };

  const hasDefectTypeSelected = (payload): boolean => {
    if (!defectData?.deferred || editResolved) return true;
    const type = !!payload?.defect_type;
    let errorHeader = '';
    let errorMessage = '';
    if (!type) {
      errorHeader = 'text.defectTypeError';
      errorMessage = 'text.pleaseSelectDefectType';
    }
    const deferralOptionsElement = document.getElementById('DeferralOptions');
    if (deferralOptionsElement && errorHeader) {
      raiseToast(formatMessage({ id: errorHeader }), formatMessage({ id: errorMessage }), ToastCategories.FLAG);
      deferralOptionsElement.scrollIntoView();
      return false;
    }
    return type;
  };

  const ataComplete = (): boolean => {
    if (!defectData?.display_data?.ata) {
      raiseToast(
        formatMessage({ id: 'text.ataError' }),
        formatMessage({ id: 'text.ataCannotBeEmpty' }),
        ToastCategories.FLAG,
      );
      return false;
    }
    return true;
  };

  const checkLimitations = (payload): boolean => {
    if (editResolved) return true;
    if (payload?.isLimitations && (payload?.limitations === '' || !payload?.limitations)) {
      raiseToast(
        formatMessage({ id: 'text.limitationsError' }),
        formatMessage({ id: 'text.limitationsPleaseAdd' }),
        ToastCategories.FLAG,
      );
      return false;
    }
    return true;
  };

  const validateLength = (value, elementId: string, length?: number, renameField?: string): boolean => {
    const formattedElementId = elementId.charAt(0).toUpperCase() + elementId.slice(1);
    if (editResolved) return true;
    if (!value || value?.length < (length || 5)) {
      raiseToast(
        formatMessage({ id: `text.${elementId}Error` }, { field: renameField }),
        renameField
          ? formatMessage({ id: `text.pleaseEnter${formattedElementId}` }, { field: renameField })
          : formatMessage({ id: `text.pleaseEnter${formattedElementId}` }),
        ToastCategories.FLAG,
      );
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView();
        element.focus();
      }

      return false;
    }
    return true;
  };

  const checkReasonToDeferDropdown = (value): boolean => {
    if (!value) {
      raiseToast(
        formatMessage({ id: `text.reasonToDeferError` }),
        formatMessage({ id: `text.pleaseEnterReasonToDefer` }),
        ToastCategories.FLAG,
      );
      const element = document.getElementById('reasonToDefer');
      if (element) {
        element.scrollIntoView();
        element.focus();
      }
      return false;
    }
    return true;
  };

  const checkRectificationExtensions = (payload): boolean => {
    if (payload.rectificationExtensionDateDue && payload.rectificationExtenstionExtendedOn) {
      return true;
    }
    if (payload.rectificationExtensionDateDue && !payload.rectificationExtenstionExtendedOn) {
      raiseToast(
        formatMessage({ id: 'text.rectificationExtensionError' }),
        formatMessage({ id: 'text.pleaseAddArectificationExtendedOnDate' }),
        ToastCategories.FLAG,
      );
      return false;
    }
    if (!payload.rectificationExtensionDateDue && payload.rectificationExtenstionExtendedOn) {
      raiseToast(
        formatMessage({ id: 'text.rectificationExtensionError' }),
        formatMessage({ id: 'text.pleaseAddARectificationNewDateDue' }),
        ToastCategories.FLAG,
      );
      return false;
    }
    return true;
  };

  const isFormValid = (payload, recTitle, payloadKeys): boolean => {
    const isABookItem =
      (payload?.defect_type === DefectType.MEL && melItems?.length) ||
      (payload?.defect_type === DefectType.CDL && cdlItems?.length) ||
      (payload?.defect_type === DefectType.NEF && nefItems?.length);
    let valid = true;
    if (!validateLength(payload.details, 'details')) valid = false;
    if (!hasDefectTypeSelected(payload)) valid = false;
    if (
      isCategoryRequired(defectData?.deferred, defectData?.defect_type, melItems, cdlItems, nefItems) &&
      !defectData?.display_data?.category
    ) {
      raiseToast(
        formatMessage({ id: 'text.rectificationCategoryError' }),
        formatMessage({ id: 'text.pleaseSelectRectificationCategory' }),
        ToastCategories.FLAG,
      );
      valid = false;
    }
    if (!(ataSelectionRequired ? ataComplete() : true)) {
      valid = false;
    }
    if (!isABookItem && !hasValidCatALimits(recTitle, payload)) valid = false;
    if (!checkAllConfirmed(payloadKeys, payload)) valid = false;
    if (!checkLimitations(payload)) valid = false;
    if (
      !(troubleShootingStepsRequired && defectData?.deferred
        ? validateLength(payload?.troubleshooting_steps, 'troubleshootingSteps', 2)
        : true)
    )
      valid = false;
    if (
      !(reasonToDeferRequired && !reasonToDeferAsDropdown && defectData?.deferred
        ? validateLength(payload?.reason_to_defer, 'reasonToDefer')
        : true)
    )
      valid = false;
    if (!(referenceRequired && !isABookItem ? validateLength(payload?.reference, 'reference') : true)) valid = false;
    if (
      !(deferUsingDefault === 'deferral_approval_reference' && defectData?.deferred
        ? validateLength(
            payload?.defer_approval_reference,
            'deferralApprovalReference',
            5,
            deferralApprovalReferenceRename,
          )
        : true)
    )
      valid = false;
    if (
      !(reasonToDeferAsDropdown && reasonToDeferRequired && defectData?.deferred
        ? checkReasonToDeferDropdown(payload?.reason_to_defer)
        : true)
    )
      valid = false;
    if (!checkRectificationExtensions(payload)) valid = false;
    return valid;
  };

  const correctDefectType = (type: string): string => {
    if (type === melNameOverride) return DefectType.MEL;
    if (type === cdlNameOverride) return DefectType.CDL;
    if (type === nefNameOverride) return DefectType.NEF;
    if (type === casNameOverride) return DefectType.CAS;
    if (type === otherNameOverride) return DefectType.Other;
    return type;
  };

  const handleFormSubmit = (confirmedIntermittentFaultConvert?: boolean): void => {
    const payload = defectData;

    const payloadKeys = [];
    let recTitle = defectData?.display_data?.category;
    if (defectData?.display_data?.category === 'Non Deferred') {
      recTitle = 'NONDEFERRED';
    }
    if (defectData?.display_data?.category === 'Advisory only') {
      recTitle = 'ADVISORYONLY';
      payload.display_data.category = 'ADVISORYONLY';
    }

    const correctedDefectType = correctDefectType(payload?.defect_type);
    if (isFormValid(payload, recTitle, payloadKeys)) {
      const defectPayload: any = {
        body: {
          ...defectData,
          attachments_attributes: defectData?.attachments || [],
          defect_type: correctedDefectType,
          rectification_category: defectData?.deferred ? defectData?.display_data?.category : null,
          flight_seconds_limit: payload?.flight_seconds_limit,
          seconds_limit: payload?.seconds_limit,
          flight_days_limit: payload?.flight_days_limit,
          cycles_limit: payload?.cycles_limit,
          flights_limit: payload?.flights_limit,
          calendar_days_limit: payload?.calendar_days_limit,
          apu_seconds_limit: payload?.apu_seconds_limit,
          other_limit: payload?.other_limit,
        },
      };
      if (defectData?.deferred && defectData?.deferred_at) {
        defectPayload.body.deferred_at = defectData?.deferred_at;
      }
      if (!defectData?.deferred) {
        defectPayload.body.rectification_category = 'NONDEFERRED';
        defectPayload.body.defect_type = null;
        defectPayload.body.limitations = null;
        defectPayload.body.defer_approval_reference = null;
        defectPayload.body.reason_to_defer = null;
      }

      if (mode === 'edit') {
        if (payload.hasRectificationExtension) {
          if (payload.rectification_interval_extension) {
            defectPayload.body.rectification_interval_extension_attributes = {
              id: payload.rectification_interval_extension.id,
              extension_date: payload.rectificationExtenstionExtendedOn,
              extension_date_due: payload.rectificationExtensionDateDue,
            };
          } else {
            defectPayload.body.rectification_interval_extension_attributes = {
              extension_date: payload.rectificationExtenstionExtendedOn,
              extension_date_due: payload.rectificationExtensionDateDue,
            };
          }
        } else if (payload.rectification_interval_extension) {
          defectPayload.body.rectification_interval_extension_attributes = {
            id: payload.rectification_interval_extension.id,
            _destroy: true,
          };
        }
      }

      const aircraftOpId = aircraftMap.get(aircraftId || id)?.operator_id;
      const reportedById = userSettings?.details?.people.find(
        (person) => person?.organisation?.id === aircraftOpId,
      )?.id;
      if (defectId || defectData?.id) {
        defectPayload.id = defectId || defectData?.id;
        defectPayload.body.aircraft_id = aircraftId || id;
        defectPayload.body.edited_by_id = reportedById;
        defectPayload.body.approval_signature_attributes = { data: signature };
        if (defectData?.deferred && !originalDefectData?.deferred_by?.id) {
          defectPayload.body.deferred_by_id = defectData?.deferred_by_id || defectData?.deferred_by?.id;
          defectPayload.body.deferred_at = moment.utc().format(userSettings?.dateFormat);
          defectPayload.body.deferral_signature_attributes = { data: signature };
        }

        updateDefect.mutate(defectPayload);
      } else {
        defectPayload.id = aircraftId;
        defectPayload.body.reported_by_id = defectData?.reported_by_id || reportedById;
        if (!defectPayload?.body?.raised_at)
          defectPayload.body.raised_at = moment.utc().format(userSettings?.dateFormat);
        if (defectData?.deferred) {
          defectPayload.body.deferred_by_id = defectData?.deferred_by_id || defect?.deferred_by?.id;
          defectPayload.body.deferred_at = moment.utc().format(userSettings?.dateFormat);
          defectPayload.body.deferral_signature_attributes = { data: signature };
        }
        defectPayload.body.signature_attributes = { data: signature };

        if (newFlight && !passedFlightId) {
          setLoading(true);
          defectPayload.body.aircraft_id = id;
          dispatch(handleBackButtonClickWithPayload({ payload: { type: 'defect', content: defectPayload.body } }));
        } else {
          if (isPassedIntermittentFault) {
            if (!confirmedIntermittentFaultConvert) {
              setModalVisible(true);
              return;
            }
            const newAttachments = defectPayload.body.attachments_attributes.filter((attachment) => !attachment.id);
            defectPayload.body.attachments_attributes = newAttachments;
            defectPayload.body.attachments = newAttachments;
          }

          addDefect.mutate(defectPayload);
        }
      }
    }
  };

  const handleFormChange = (): void => {
    setFormChanged(true);
  };

  const cancelClick = (): void => {
    if (formChanged && (mode === 'edit' || mode === 'add')) {
      dispatch(changeModalVisibility({ payload: true }));
    } else {
      if (drawerHistory) {
        dispatch(handleBackButtonClick());
      } else {
        dispatch(changeDrawerVisibility({ payload: false }));
      }
      if (defect?.approval_signature_image_url) {
        setSignature(defect?.approval_signature_image_url);
      } else {
        setSignature('');
      }
      setFormChanged(false);
    }
  };

  useEffect(() => {
    if (!_.isEqual(defectsMap, prevDefectsMap) || !_.isEqual(prevDrawerDefect, drawerDefect)) {
      setLoading(false);
    }
  }, [defectsMap, prevDefectsMap]);

  useEffect(() => {
    if (drawerDefect) {
      setLoading(false);
    }
  }, [drawerDefect]);

  useEffect(() => {
    if (id) setAircraftId(id);
  }, [id, mode]);

  useEffect(() => {
    if (formChanged) {
      setSignature('');
      dispatch(setDrawerChanges({ payload: true }));
    }
  }, [formChanged]);

  useEffect(() => {
    setLoading(defectLoading);
  }, [defectLoading]);

  useEffect(() => {
    if (queryDefectData) {
      setDefectData(queryDefectData);
      setOriginalDefectData(queryDefectData);
      setLoading(false);
      dispatch({
        type: DefectsActionTypes.SAVE,
        payload: [queryDefectData],
      })
    }
  }, [queryDefectData]);

  useEffect(() => {
    if (passedFlightId) {
      updateDefectData([{ value: passedFlightId, key: 'flight_id' }]);
    }
  }, [passedFlightId]);

  useEffect(() => {
    if (deferDefect) {
      updateDefectData([{ value: true, key: 'deferred' }]);
    }
  }, [deferDefect]);

  useEffect(() => {
    if (defect?.deferred) {
      const header = document.getElementById('DefectTitle');
      if (header) {
        header.scrollIntoView();
      }
    }
  }, [defect?.deferred]);

  useEffect(() => {
    if (revertChanges) {
      resetForm();
      if (defectId) {
        const payload = { id: defectId };
        setLoading(true);
        dispatch(getDefectForDrawer(payload));
        setFormChanged(false);
      } else {
        setDefectData({
          raised_at: moment.utc().toISOString(),
        });
        setOriginalDefectData({
          raised_at: moment.utc().toISOString(),
        });
        setSignature('');
        setFormChanged(false);
      }
      dispatch(reduxRevertChanges({ payload: false }));
      dispatch(setDrawerChanges({ payload: false }));
    }
  }, [revertChanges]);

  useEffect(() => {
    const tfDrawerWrapper = document.getElementById('tfDrawerWrapper');
    if (tfDrawerWrapper) {
      if (loading) {
        tfDrawerWrapper.setAttribute('style', 'overflow: hidden');
      } else if (window.innerWidth > 450) {
        tfDrawerWrapper.setAttribute('style', 'overflow: auto');
      }
    }
  }, [loading]);

  useEffect(() => {
    const customiser = (objValue, otherValue, key): boolean => {
      if (key === 'raised_at') {
        if (moment(objValue).format('YYYY-MM-DD HH:mm') === moment(otherValue).format('YYYY-MM-DD HH:mm')) return true;
      }
      if (key === 'versions') return true;
      return undefined;
    };

    if (!_.isEqualWith(defectData, originalDefectData, customiser)) {
      setFormChanged(true);
      setSignature('');
    } else {
      setFormChanged(false);
    }
  }, [defectData, originalDefectData]);

  let buttonDisabled = false;
  if (signature && !formChanged && defect) {
    buttonDisabled = true;
  }
  if (!signature) {
    buttonDisabled = true;
  }
  if (
    defectData?.deferred &&
    ((defectData?.defect_type === 'MEL' && melItems?.length > 0) ||
      (defectData?.defect_type === 'CDL' && cdlItems?.length > 0) ||
      (defectData?.defect_type === 'NEF' && nefItems?.length > 0)) &&
    !defectData?.book_item?.id
  ) {
    buttonDisabled = true;
  }

  useEffect(() => {
    if (isMelItemsLoading || isCdlItemsLoading || isNefItemsLoading) {
      setBookItemsLoading(true);
    }
    if (!isMelItemsLoading && !isCdlItemsLoading && !isNefItemsLoading) {
      setBookItemsLoading(false);
    }
  }, [isMelItemsLoading, isCdlItemsLoading, isNefItemsLoading]);

  useEffect(() => {
    if (passedData && passedData.type === 'fault') {
      setIsPassedIntermittentFault(true);
      const passedFaultData = passedData.content as IntermittentFault;
      const IntermittentFaultID = passedFaultData.id;
      delete passedFaultData.id;
      setDefectData({
        ...passedFaultData,
        raised_at: passedFaultData.reported_at,
        intermittent_fault_id: IntermittentFaultID,
      });
    }
  }, [passedData]);

  return (
    <DrawerWrapper id="DefectDrawerWrapper" data-testid="DefectDrawer--DrawerWrapper">
      <Loading loading={loading} contain />
      {mode === 'view' ? (
        <ViewDefectDrawer
          defectData={defectData}
          aircraftId={aircraftId}
          updateDefectData={updateDefectData}
          flightsData={flightsData}
          isLoadingFlights={isLoadingFlights}
          setLoading={setLoading}
          requiredSettings={{
            troubleShootingStepsRename,
            deferralApprovalReferenceRename,
            defectRename,
            reportedByTypeEnabled,
            stageOfFlightEnabled,
            stageOfFlightText,
            eropsRename,
            part145ApprovalNumberRename,
            engineerRename,
            engineerLicenceNumberRename,
            melNameOverride,
            cdlNameOverride,
            nefNameOverride,
            casNameOverride,
            otherNameOverride,
            showRichTextMel,
            dateFormat: userSettings.dateFormat,
            editResolved,
            resolutionRename,
            currentUserId,
            currentLoginId,
            approvalReleaseName,
          }}
        />
      ) : (
        <EditDefectDrawer
          defectData={defectData}
          originalDefectData={originalDefectData}
          updateDefectData={updateDefectData}
          aircraftId={aircraftId}
          flightsData={flightsData}
          isLoadingFlights={isLoadingFlights}
          setAircraftId={setAircraftId}
          formChanged={formChanged}
          chosenAircraft={chosenAircraft}
          signOffAsEngineer={signOffAsEngineer}
          setSignOffAsEngineer={setSignOffAsEnginner}
          handleFormChange={handleFormChange}
          bookItemsLoading={bookItemsLoading}
          melItems={melItems}
          cdlItems={cdlItems}
          nefItems={nefItems}
          mode={mode}
          requiredSettings={{
            troubleShootingStepsRename,
            troubleShootingStepsRequired,
            reasonToDeferRequired,
            reasonToDeferAsDropdown,
            reasonToDeferDropdownOptions,
            eropsRename,
            sectorsNameOverride,
            ignoreEROPS,
            defectRename,
          }}
        />
      )}
      {mode !== 'view' || editResolved ? (
        <>
          {(formChanged || !defect) && (
            <SignatureSection
              handleSignature={handleSignature}
              signature={signature}
              formChanged={formChanged}
              mode={mode}
              revertChanges={revertChanges}
            />
          )}
          <ButtonSection
            handleCancel={cancelClick}
            handleFormSubmit={() => handleFormSubmit(false)}
            loading={bookItemsLoading || loading}
            disabled={buttonDisabled}
          />
        </>
      ) : null}
      <Modal width={420} isOpen={modalVisible} handleClose={(): void => setModalVisible(false)}>
        <ModalContentWrapper>
          <ModalTitle>{formatMessage({ id: 'title.convertDefect' })}</ModalTitle>
          <ModalMessage>
            {`${formatMessage({
              id: 'form.question.areYouSureConvertFault',
            })}`}
          </ModalMessage>
          <ModalButtonWrapper>
            <SubminButtonWraper>
              <Button
                padding="0 28px"
                size={ButtonSize.MEDIUM}
                onClick={(): void => {
                  setModalVisible(false);
                  handleFormSubmit(true);
                  history.push(`/aircraft/${id}/defects/log`);
                }}
              >
                Confirm
              </Button>
            </SubminButtonWraper>
            <Button
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={(): void => {
                setModalVisible(false);
                setLoading(false);
              }}
            >
              Cancel
            </Button>
          </ModalButtonWrapper>
        </ModalContentWrapper>
      </Modal>
      {mode === 'view' ? <HistorySection title="defect" versions={defectData?.versions} /> : null}
    </DrawerWrapper>
  );
};

DefectDrawer.defaultProps = {
  passedFlightId: undefined,
  deferDefect: false,
  newFlight: false,
};

export default DefectDrawer;
