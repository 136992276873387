import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import TFFilerViewer from '../../TFFileViewer/fileViewer';
import { ApprovalRelease as ApprovalReleaseType } from '../../../models/defects';
import { Header, Text } from '../../CommonStyledComponents/CommonStyledComponents';
import ViewSignatureRow from './ViewSignatureRow';

interface ApprovalReleaseProps {
  approvalRelease: ApprovalReleaseType;
  approvalReleaseName: string;
}

const Wrapper = styled.div`
  margin-top: 30px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-weight: 500;
  color: #242d41;
`;

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
`;

const ApprovalRelease: React.FC<ApprovalReleaseProps> = ({ approvalRelease, approvalReleaseName }) => {
  const { formatMessage } = useIntl();
  return (
    <Wrapper data-testid="ApprovalRelease--Wrapper">
      <TitleWrapper>
        <Title data-testid="ApprovalRelease--Title">
          {formatMessage({ id: 'text.releaseDetails' }, { field: approvalReleaseName })}
        </Title>
      </TitleWrapper>
      <InnerWrapper>
        <div data-testid="ApprovalRelease--Notes">
          <Header>{formatMessage({ id: 'text.notes' })}</Header>
          <Text>{approvalRelease.notes}</Text>
        </div>
        <div data-testid="ApprovalRelease--Attachments">
          <Header>{formatMessage({ id: 'text.attachments' })}</Header>
          <TFFilerViewer attachments={approvalRelease?.attachments} uploaderVisible={false} />
        </div>
      </InnerWrapper>
      <ViewSignatureRow
        partialDefectData={{
          person: {
            first_name: approvalRelease?.first_name,
            last_name: approvalRelease?.last_name,
            id: approvalRelease?.id
          },
          date: approvalRelease?.date,
          signatureData: approvalRelease?.signature_data
        }}
        dateFormat="YYYY-MM-DD"
        type="Signed by:"
        noDivider
      />
    </Wrapper>
  );
};

export default ApprovalRelease;
