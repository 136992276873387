/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Accordion, Button } from '@arcflight/tf-component-library';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import { DataValue } from '../../utils/updateLocalDataObject';
import ToolTip from '../NewTooltip/NewTooltip';
import defectsIcon from '../../assets/icon-card-defects.svg';
import TFSelectDropdown from '../TFSelectDropdown/TFSelectDropdown';
import useGetAircraftStandardFieldProperty from '../../services/hooks/aircraft/useGetAircraftStandardFieldProperty';
import AircraftSettingsCard from './AircraftSettingsCard';
import ShowHideToggle from './ShowHideToggle';
import {
  AccordionWrapper,
  ButtonWrapper,
  ItemWrapper,
  Label,
  SaveWrapper,
  StyledGrid,
  StyledInput,
  Text,
} from './AircraftAdvancedSettings';

interface AircraftDefectStandardFieldsProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: DataValue; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
}

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.08px;
  color: #181818;
  margin: 0 16px;
  padding-top: 16px;
  border-top: solid 1px rgba(36, 45, 65, 0.05);
  margin-bottom: 16px;
`;

const SubHeading = styled.div`
  font-size: 10px;
  line-height: 1.6;
  color: rgba(36, 45, 65, 0.8);
  margin-left: 16px;
  margin-bottom: 24px;
`;

const AircraftDefectStandardFields: React.FC<AircraftDefectStandardFieldsProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [localReasonToDeferOptions, setLocalReasonToDeferOptions] = useState([]);
  const [selectedReasonToDefer, setSelectedReasonToDefer] = useState([]);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const defectReferenceRequired = useGetAircraftStandardFieldProperty<boolean>(
    'defect_reference',
    id,
    'required',
  );

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    handleSaveClick();
    setEditMode(false);
  };

  const handleToggleChange = (value: boolean, key: string, required = false): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    if (required) {
      newStandardFields[`${key}`] = {
        ...newStandardFields[`${key}`],
        required: value,
      };
    } else {
      newStandardFields[`${key}`] = { ...newStandardFields[`${key}`], enabled: value };
    }
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleReasonToDeferDropdownChange = (value: boolean): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields.reason_to_defer = { ...newStandardFields?.reason_to_defer, dropdown_enabled: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleReasonToDeferOptionAddRemoveChange = (value: string, method: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    if (method === 'add') {
      localReasonToDeferOptions.push(value);
      newStandardFields.reason_to_defer = {
        ...newStandardFields.reason_to_defer,
        dropdown_options_available: localReasonToDeferOptions,
      };
      setLocalReasonToDeferOptions(localReasonToDeferOptions);
    } else if (method === 'remove') {
      const newOptions = localReasonToDeferOptions.filter((option) => option !== value);
      newStandardFields.reason_to_defer = {
        ...newStandardFields.reason_to_defer,
        reason_to_defer_dropdown_options: newOptions,
      };
      setLocalReasonToDeferOptions(newOptions);
    }
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleSelectChange = (value: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    const newOptions = [...selectedReasonToDefer];
    const optionIndex = selectedReasonToDefer.findIndex((op) => op === value);
    if (optionIndex !== -1) {
      newOptions.splice(optionIndex, 1);
    } else {
      newOptions.push(value);
    }
    newStandardFields.reason_to_defer = {
      ...newStandardFields.reason_to_defer,
      dropdown_options_selected: newOptions,
    };
    setSelectedReasonToDefer(newOptions);
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleNameChange = (value: string, key: string, secondKey: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { ...newStandardFields[`${key}`], [`${secondKey}`]: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  useEffect(() => {
    if (aircraft?.standard_fields?.reason_to_defer?.dropdown_options_available) {
      setLocalReasonToDeferOptions(aircraft?.standard_fields?.reason_to_defer?.dropdown_options_available);
    } else if (!aircraft?.standard_fields?.reason_to_defer?.dropdown_options_available) {
      setLocalReasonToDeferOptions(['People', 'Time', 'Parts']);
    }
    if (aircraft?.standard_fields?.reason_to_defer?.dropdown_options_selected) {
      setSelectedReasonToDefer(aircraft?.standard_fields?.reason_to_defer?.dropdown_options_selected);
    }
  }, [aircraft]);

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={defectsIcon}
            title="Defect workflow configuration"
            buttonText="Edit Defect workflow configuration"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
          />
        }
      >
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>
              {formatMessage({ id: 'text.defectsReported' })}
              <ToolTip text="Requires crew to record defects or confirm that no defects need reporting during postflight" />
            </Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.defects_reported?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.defects_reported?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'defects_reported')}
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>
              ATA selection required
              <ToolTip text="If enabled, ATA selection is required to proceed with defect workflows" />
            </Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.ata_selection?.required ? 'Yes' : 'No'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.ata_selection?.required}
                handleChange={(value: boolean): void => handleToggleChange(value, 'ata_selection', true)}
                showOverride="Yes"
                hideOverride="No"
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>
              {formatMessage({ id: 'text.troubleshootingStepsRequired' })}
              <ToolTip text="If enabled, troubleshooting steps are required to proceed with defect deferrals" />
            </Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.troubleshooting_steps?.required ? 'Yes' : 'No'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.troubleshooting_steps?.required}
                handleChange={(value: boolean): void => handleToggleChange(value, 'troubleshooting_steps', true)}
                showOverride="Yes"
                hideOverride="No"
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>{formatMessage({ id: 'text.troubleshootingStepsNameOverride' })}</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.troubleshooting_steps?.name_override || '-'}</Text>
            ) : (
              <StyledInput
                value={aircraft?.standard_fields?.troubleshooting_steps?.name_override}
                type="text"
                onChange={(e): void => handleNameChange(e.target.value, 'troubleshooting_steps', 'name_override')}
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>Rich text MEL</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.rich_text_mel?.enabled ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.rich_text_mel?.enabled}
                handleChange={(value: boolean): void => handleToggleChange(value, 'rich_text_mel')}
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>
              Reason to defer required
              <ToolTip text="If enabled, reason to defer is required to proceed with defect deferrals" />
            </Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.reason_to_defer?.required ? 'Yes' : 'No'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.reason_to_defer?.required}
                handleChange={(value: boolean): void => handleToggleChange(value, 'reason_to_defer', true)}
                showOverride="Yes"
                hideOverride="No"
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>
              Reason to defer as dropdown
              <ToolTip text="If enabled, reason to defer becomes a dropdown to select" />
            </Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.reason_to_defer?.dropdown_enabled ? 'Yes' : 'No'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.reason_to_defer?.dropdown_enabled}
                handleChange={(value: boolean): void => handleReasonToDeferDropdownChange(value)}
                showOverride="Yes"
                hideOverride="No"
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.reason_to_defer?.dropdown_enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>Reason to defer dropdown options</Label>
              {!editMode ? (
                <Text>{selectedReasonToDefer.join(', ')}</Text>
              ) : (
                <TFSelectDropdown
                  options={localReasonToDeferOptions}
                  selected={selectedReasonToDefer}
                  handleSelectChange={handleSelectChange}
                  handleRemoveClick={handleSelectChange}
                  addOption
                  handleAddClick={(option): void => handleReasonToDeferOptionAddRemoveChange(option, 'add')}
                  handleDeleteClick={(option): void => handleReasonToDeferOptionAddRemoveChange(option, 'remove')}
                />
              )}
            </ItemWrapper>
          ) : null}
          <ItemWrapper>
            <Label edit={editMode}>
              {formatMessage({ id: 'text.referenceRequired' })}
              <ToolTip text={formatMessage({ id: 'text.ifEnabledReferenceRequired' })} />
            </Label>
            {!editMode ? (
              <Text>{defectReferenceRequired ? 'Yes' : 'No'}</Text>
            ) : (
              <ShowHideToggle
                value={defectReferenceRequired}
                handleChange={(value: boolean): void => handleToggleChange(value, 'defect_reference', true)}
                showOverride="Yes"
                hideOverride="No"
              />
            )}
          </ItemWrapper>
        </StyledGrid>
        <Title>Deferral options</Title>
        <SubHeading>
          These settings change the options for deferral shown throughout the system. The MEL option will use a Digital
          MEL if available, and items deferred under the NEF option will never expire. The other deferral types behave
          the same
        </SubHeading>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>MEL deferral type</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.deferral_type_mel?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.deferral_type_mel?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'deferral_type_mel')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.deferral_type_mel?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>MEL name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_mel?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_mel?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'deferral_type_mel', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.deferral_type_mel?.enabled !== false ? (
            <ItemWrapper span={editMode ? '3' : null}>
              <Label edit={editMode}>MEL long name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_mel?.long_name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_mel?.long_name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'deferral_type_mel', 'long_name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>CDL deferral type</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.deferral_type_cdl?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.deferral_type_cdl?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'deferral_type_cdl')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.deferral_type_cdl?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>CDL name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_cdl?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_cdl?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'deferral_type_cdl', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.deferral_type_cdl?.enabled !== false ? (
            <ItemWrapper span={editMode ? '3' : null}>
              <Label edit={editMode}>CDL long name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_cdl?.long_name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_cdl?.long_name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'deferral_type_cdl', 'long_name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>CAS deferral type</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.deferral_type_cas?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.deferral_type_cas?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'deferral_type_cas')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.deferral_type_cas?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>CAS name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_cas?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_cas?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'deferral_type_cas', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.deferral_type_cas?.enabled !== false ? (
            <ItemWrapper span={editMode ? '3' : null}>
              <Label edit={editMode}>CAS long name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_cas?.long_name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_cas?.long_name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'deferral_type_cas', 'long_name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>NEF deferral type</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.deferral_type_nef?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.deferral_type_nef?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'deferral_type_nef')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.deferral_type_nef?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>NEF name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_nef?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_nef?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'deferral_type_nef', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.deferral_type_nef?.enabled !== false ? (
            <ItemWrapper span={editMode ? '3' : null}>
              <Label edit={editMode}>NEF long name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_nef?.long_name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_nef?.long_name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'deferral_type_nef', 'long_name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Other deferral type</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.deferral_type_other?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.deferral_type_other?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'deferral_type_other')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.deferral_type_other?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Other name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_other?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_other?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'deferral_type_other', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.deferral_type_other?.enabled !== false ? (
            <ItemWrapper span={editMode ? '3' : null}>
              <Label edit={editMode}>Other long name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_other?.long_name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_other?.long_name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'deferral_type_other', 'long_name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Non deferred deferral type</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.deferral_type_non_deferred?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.deferral_type_non_deferred?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'deferral_type_non_deferred')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.deferral_type_non_deferred?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Non deferred name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_non_deferred?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_non_deferred?.name_override}
                  type="text"
                  onChange={(e): void =>
                    handleNameChange(e.target.value, 'deferral_type_non_deferred', 'name_override')
                  }
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.deferral_type_non_deferred?.enabled !== false ? (
            <ItemWrapper span={editMode ? '3' : null}>
              <Label edit={editMode}>Non deferred long name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.deferral_type_non_deferred?.long_name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.deferral_type_non_deferred?.long_name_override}
                  type="text"
                  onChange={(e): void =>
                    handleNameChange(e.target.value, 'deferral_type_non_deferred', 'long_name_override')
                  }
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button height="32px" onClick={handleLocalSaveClick}>
                Save
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              Cancel
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftDefectStandardFields;
