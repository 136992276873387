import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { createNewFRAT } from '../../services/api';
import { FRAT } from '../../models/frats';

type FRATError = Error & {
  statusText?: string;
  errors?: {
    name: string[];
  };
};

interface AddNewFRATProps {
  handleAddFRATOnSuccess: (data: FRAT) => void;
  handleAddFRATOnError: (err: FRATError) => void;
}

const useAddNewFRAT = ({
  handleAddFRATOnSuccess,
  handleAddFRATOnError
}: AddNewFRATProps): UseMutationResult<FRAT, FRATError, unknown, unknown> => {
  const addNewFRAT = useMutation({
    mutationFn: (payload: { name: string; default: boolean; operator_id: string }) => {
      return createNewFRAT(payload);
    },
    onSuccess: (data) => {
      handleAddFRATOnSuccess(data);
    },
    onError: (err: FRATError) => handleAddFRATOnError(err),
  });

  return addNewFRAT;
};

export default useAddNewFRAT;
