export interface Error {
  error: {
    [key: string]: string;
  };
}

const coreErrorMessageFormatter = (error: Error): string => {
  if (!error) {
    return '';
  }
  let errorMessage = '';
  if (error.error) {
    const unformattedError = error.error;
    if (unformattedError) {
      const separated = Object.entries(unformattedError);
      const formattedKey = separated[0][0]?.replace(/[_.]/g, ' ');
      const formattedValue = separated[0][1];
      errorMessage = `${formattedKey} ${formattedValue}`;
    }
  }
  return errorMessage;
};

interface Errors {
  [key: string]: string[];
}

export function core422ErrorMessageFormatter(errors: Errors): string {
  const errorMessages: string[] = [];

  for (const key in errors) {
    if (Object.hasOwnProperty.call(errors, key)) {
      const values = errors[key];
      // Capitalize the key and join the values with 'and'
      errorMessages.push(`${key.charAt(0).toUpperCase() + key.slice(1)} ${values.join(' and ')}`);
    }
  }

  // Join all error messages with a period and space
  return errorMessages.join('. ');
}

export default coreErrorMessageFormatter;
