import React from 'react';
import { Defect } from '../../../models/defects';
import { Flight } from '../../../models/flights';
import ViewDefectDrawerHeader from './ViewDefectDrawerHeader';
import Card from './Card';
import ViewDefectDrawerDetails from './ViewDefectDrawerDetails';

interface ViewDefectDrawerProps {
  defectData: Defect;
  aircraftId: string;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  flightsData: Flight[];
  isLoadingFlights: boolean;
  requiredSettings: {
    troubleShootingStepsRename: string;
    deferralApprovalReferenceRename: string;
    defectRename: string;
    reportedByTypeEnabled: boolean;
    stageOfFlightEnabled: boolean;
    stageOfFlightText: string;
    eropsRename: string;
    part145ApprovalNumberRename: string;
    engineerRename: string;
    engineerLicenceNumberRename: string;
    melNameOverride: string;
    cdlNameOverride: string;
    nefNameOverride: string;
    casNameOverride: string;
    otherNameOverride: string;
    showRichTextMel: boolean;
    dateFormat: string;
    editResolved: boolean;
    resolutionRename: string;
    currentLoginId: string;
    currentUserId: string;
    approvalReleaseName: string;
  };
  setLoading: (value: boolean) => void;
}
const ViewDefectDrawer: React.FC<ViewDefectDrawerProps> = ({
  defectData,
  aircraftId,
  updateDefectData,
  flightsData,
  isLoadingFlights,
  requiredSettings,
  setLoading,
}) => {
  return (
    <div>
      <ViewDefectDrawerHeader
        defectData={defectData}
        aircraftId={aircraftId}
        updateDefectData={updateDefectData}
        flightsData={flightsData}
        isLoadingFlights={isLoadingFlights}
      />
      <Card marginBottom={20} identifier="DefectDetailsWrapper">
        <ViewDefectDrawerDetails
          defectData={defectData}
          editResolved={requiredSettings.editResolved}
          updateDefectData={updateDefectData}
          requiredSettings={requiredSettings}
          setLoading={setLoading}
        />
      </Card>
    </div>
  );
};

export default ViewDefectDrawer;
