import { Accordion, Button } from '@arcflight/tf-component-library';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import ipadIcon from '../../assets/icon-card-signature-permissions.svg';
import { Aircraft, AppPermission, BaseAircraft } from '../../models/aircraft';
import TFSelectDropdown from '../TFSelectDropdown/TFSelectDropdown';
import { DataValue } from '../../utils/updateLocalDataObject';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import { AccordionWrapper } from './AircraftAdvancedSettings';
import AircraftSettingsCard from './AircraftSettingsCard';

interface AircraftIPadPermissionsProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: DataValue; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 24px;
  padding: 0 16px 16px 16px;
  @media (max-width: 1720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1540px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1310px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1020px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Label = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
  color: ${({ edit }): string => (edit ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  font-weight: ${({ edit }): string => (edit ? '600' : '400')};
  line-height: ${({ edit }): string => (edit ? '1.33' : 'normal')};
  text-transform: ${({ edit }): string => (edit ? 'none' : 'uppercase')};
`;

const Text = styled.div`
  line-height: 1.43;
  color: rgba(36, 54, 65, 0.7);
  min-height: 18px;
`;

const ItemWrapper = styled.div`
  grid-column: ${({ span, spanAll }): string => {
    if (spanAll) {
      return `1 / -1`;
    }
    if (span) {
      return `span ${span}`;
    }
    return 'auto';
  }};
  padding-right: 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 16px 0 16px 16px;
`;

const SaveWrapper = styled.div`
  margin-right: 16px;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: ${({ alignCenter }): string => (alignCenter ? 'center' : 'unset')};
`;

const TipText = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: rgba(36, 45, 65, 0.7);
  margin-left: 8px;
`;

const PERSON_POSITIONS = [
  'Admin',
  'Camo',
  'Captain',
  'Crew',
  'Engineer',
  'First Officer',
  'Flight Attendant',
  'Ops Controller',
  'Pilot',
  'Relief Pilot',
];

const TRIP_MODE_BASE_PERMISSIONS = {
  trip: {
    can_sign: 'anyone',
    can_sign_scope_type: 'trip_role',
  },
  preflight: {
    can_sign: 'anyone',
    can_sign_scope_type: 'trip_role',
  },
  postflight: {
    can_sign: 'anyone',
    can_sign_scope_type: 'trip_role',
  },
} as AppPermission;

const PERSON_MODE_BASE_PERMISSIONS = {
  trip: {
    can_sign: [],
    can_sign_scope_type: 'person_position',
  },
  preflight: {
    can_sign: [],
    can_sign_scope_type: 'person_position',
  },
  postflight: {
    can_sign: [],
    can_sign_scope_type: 'person_position',
  },
  mx_check: {
    can_sign: [],
    can_sign_scope_type: 'person_position',
  },
  crs: {
    can_sign: [],
    can_sign_scope_type: 'person_position',
  },
  issue: {
    can_sign: [],
    can_sign_scope_type: 'person_position',
  },
  uplift: {
    can_sign: [],
    can_sign_scope_type: 'person_position',
  },
} as AppPermission;

const AircraftIPadPermissions: React.FC<AircraftIPadPermissionsProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [permissionMode, setPermissionMode] = useState('trip');

  const { formatMessage } = useIntl();

  const handleInputChange = (value: string, key: string): void => {
    const newAppPermissions = aircraft?.app_permissions;
    newAppPermissions[`${key}`] = { can_sign: value };
    updateAircraftData([{ value: newAppPermissions, key: 'app_permissions' }]);
  };

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    setEditMode(false);
    handleSaveClick();
  };

  const addSpacesWhereAppropriate = (input: string[]): string[] => {
    const rolesWithoutSpaces = ['FirstOfficer', 'FlightAttendant', 'OpsController', 'ReliefPilot'];
    const rolesWithSpaces = ['First Officer', 'Flight Attendant', 'Ops Controller', 'Relief Pilot'];
    const newInput = input.map((word) => {
      const index = rolesWithoutSpaces.findIndex((role) => role === word);
      if (index !== -1) {
        return rolesWithSpaces[index];
      }
      return word;
    });
    return newInput;
  };

  const formattedDisplayText = (input: string | string[]): string => {
    if (permissionMode === 'trip') {
      if (input === 'captain') return 'PIC';
      if (input === 'captain_or_first_officer') return 'PIC or SIC';
      return 'Anyone';
    }
    // necessary line to keep typescript happy? 🤷‍♂️
    if (typeof input === 'string') {
      return input;
    }
    if (!input?.length) return 'Anyone';
    const newInput = addSpacesWhereAppropriate(input);
    return newInput.join(', ');
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  const handleSelectChange = (option, key: string): void => {
    const formattedOption = option.replace(/ /g, '');
    const newAppPermissions = aircraft?.app_permissions;
    const newOptions = newAppPermissions[`${key}`].can_sign;
    const optionIndex = newOptions.findIndex((op) => op === formattedOption);
    if (optionIndex !== -1) {
      newOptions.splice(optionIndex, 1);
    } else {
      newOptions.push(formattedOption);
    }
    const formattedNewOptions = newOptions.map((op) => op.replace(/ /g, ''));
    newAppPermissions[`${key}`] = { ...newAppPermissions[`${key}`], can_sign: formattedNewOptions };
    updateAircraftData([{ value: newAppPermissions, key: 'app_permissions' }]);
  };

  const handlePermissionModeChange = (value: string): void => {
    setPermissionMode(value);
    if (value === 'trip') {
      updateAircraftData([{ value: TRIP_MODE_BASE_PERMISSIONS, key: 'app_permissions' }]);
    } else if (value === 'person') {
      updateAircraftData([{ value: PERSON_MODE_BASE_PERMISSIONS, key: 'app_permissions' }]);
    }
  };

  useEffect(() => {
    if (!aircraft?.app_permissions) {
      const newAppPermissions = TRIP_MODE_BASE_PERMISSIONS;
      setPermissionMode('trip');
      updateAircraftData([{ value: newAppPermissions, key: 'app_permissions' }]);
    } else if (aircraft?.app_permissions?.trip?.can_sign_scope_type === 'person_position') {
      setPermissionMode('person');
    } else if (aircraft?.app_permissions?.trip?.can_sign_scope_type === 'trip_role' && permissionMode !== 'trip') {
      setPermissionMode('trip');
    } else if (!aircraft?.app_permissions?.trip?.can_sign_scope_type) {
      const newPermissions = {
        trip: {
          can_sign: aircraft?.app_permissions?.trip?.can_sign,
          can_sign_scope_type: 'trip_role',
        },
        preflight: {
          can_sign: aircraft?.app_permissions?.preflight?.can_sign,
          can_sign_scope_type: 'trip_role',
        },
        postflight: {
          can_sign: aircraft?.app_permissions?.postflight?.can_sign,
          can_sign_scope_type: 'trip_role',
        },
      };
      setPermissionMode('trip');
      updateAircraftData([{ value: newPermissions, key: 'app_permissions' }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aircraft]);

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={ipadIcon}
            title="iPad Application signature permissions"
            buttonText="Edit permissions"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
            subTitle="These settings impact who is able to sign or create various types of data.
            Please note that permissions are ignored for 'Admin' users."
          />
        }
      >
        {editMode ? (
          <StyledGrid>
            <ItemWrapper spanAll>
              <Label edit>{formatMessage({ id: 'text.selectMode' })}</Label>
              <RadioWrapper alignCenter>
                <RadioInputButton
                  text="Trip mode"
                  active={permissionMode === 'trip'}
                  marginRight="8px"
                  onClick={(): void => handlePermissionModeChange('trip')}
                  dataTestId="RadioInputButtonTripMode"
                />
                <RadioInputButton
                  text="Person mode"
                  active={permissionMode === 'person'}
                  marginRight="8px"
                  onClick={(): void => handlePermissionModeChange('person')}
                  dataTestId="RadioInputButtonPersonMode"
                />
                {permissionMode === 'person' ? (
                  <TipText>{formatMessage({ id: 'text.personModeTooltip' })}</TipText>
                ) : null}
              </RadioWrapper>
            </ItemWrapper>
          </StyledGrid>
        ) : null}
        <StyledGrid>
          <ItemWrapper span={editMode ? '2' : null} data-testid="AircraftIPadPermissions--Trip">
            <Label edit={editMode}>{formatMessage({ id: 'text.canCloseTrip' })}</Label>
            {!editMode ? (
              <Text>{formattedDisplayText(aircraft?.app_permissions?.trip?.can_sign)}</Text>
            ) : (
              <RadioWrapper>
                {permissionMode === 'trip' ? (
                  <>
                    <RadioInputButton
                      text="Anyone"
                      active={aircraft?.app_permissions?.trip?.can_sign === 'anyone'}
                      marginRight="8px"
                      onClick={(): void => handleInputChange('anyone', 'trip')}
                    />
                    <RadioInputButton
                      text="PIC only"
                      active={aircraft?.app_permissions?.trip?.can_sign === 'captain'}
                      marginRight="8px"
                      onClick={(): void => handleInputChange('captain', 'trip')}
                    />
                    <RadioInputButton
                      text="PIC or SIC"
                      active={aircraft?.app_permissions?.trip?.can_sign === 'captain_or_first_officer'}
                      marginRight="8px"
                      onClick={(): void => handleInputChange('captain_or_first_officer', 'trip')}
                    />
                  </>
                ) : (
                  <TFSelectDropdown
                    options={PERSON_POSITIONS}
                    selected={addSpacesWhereAppropriate(aircraft?.app_permissions?.trip?.can_sign)}
                    handleSelectChange={(option): void => handleSelectChange(option, 'trip')}
                    handleRemoveClick={(option): void => handleSelectChange(option, 'trip')}
                  />
                )}
              </RadioWrapper>
            )}
          </ItemWrapper>
          <ItemWrapper span={editMode ? '2' : null} data-testid="AircraftIPadPermissions--Preflight">
            <Label edit={editMode}>{formatMessage({ id: 'text.canSignPreflight' })}</Label>
            {!editMode ? (
              <Text>{formattedDisplayText(aircraft?.app_permissions?.preflight?.can_sign)}</Text>
            ) : (
              <RadioWrapper>
                {permissionMode === 'trip' ? (
                  <>
                    <RadioInputButton
                      text="Anyone"
                      active={aircraft?.app_permissions?.preflight?.can_sign === 'anyone'}
                      marginRight="8px"
                      onClick={(): void => handleInputChange('anyone', 'preflight')}
                    />
                    <RadioInputButton
                      text="PIC only"
                      active={aircraft?.app_permissions?.preflight?.can_sign === 'captain'}
                      marginRight="8px"
                      onClick={(): void => handleInputChange('captain', 'preflight')}
                    />
                    <RadioInputButton
                      text="PIC or SIC"
                      active={aircraft?.app_permissions?.preflight?.can_sign === 'captain_or_first_officer'}
                      marginRight="8px"
                      onClick={(): void => handleInputChange('captain_or_first_officer', 'preflight')}
                    />
                  </>
                ) : (
                  <TFSelectDropdown
                    options={PERSON_POSITIONS}
                    selected={addSpacesWhereAppropriate(aircraft?.app_permissions?.preflight?.can_sign)}
                    handleSelectChange={(option): void => handleSelectChange(option, 'preflight')}
                    handleRemoveClick={(option): void => handleSelectChange(option, 'preflight')}
                  />
                )}
              </RadioWrapper>
            )}
          </ItemWrapper>
          <ItemWrapper span={editMode ? '2' : null} data-testid="AircraftIPadPermissions--Postflight">
            <Label edit={editMode}>{formatMessage({ id: 'text.canSignPostflight' })}</Label>
            {!editMode ? (
              <Text>{formattedDisplayText(aircraft?.app_permissions?.postflight?.can_sign)}</Text>
            ) : (
              <RadioWrapper>
                {permissionMode === 'trip' ? (
                  <>
                    <RadioInputButton
                      text="Anyone"
                      active={aircraft?.app_permissions?.postflight?.can_sign === 'anyone'}
                      marginRight="8px"
                      onClick={(): void => handleInputChange('anyone', 'postflight')}
                    />
                    <RadioInputButton
                      text="PIC only"
                      active={aircraft?.app_permissions?.postflight?.can_sign === 'captain'}
                      marginRight="8px"
                      onClick={(): void => handleInputChange('captain', 'postflight')}
                    />
                    <RadioInputButton
                      text="PIC or SIC"
                      active={aircraft?.app_permissions?.postflight?.can_sign === 'captain_or_first_officer'}
                      marginRight="8px"
                      onClick={(): void => handleInputChange('captain_or_first_officer', 'postflight')}
                    />
                  </>
                ) : (
                  <TFSelectDropdown
                    options={PERSON_POSITIONS}
                    selected={addSpacesWhereAppropriate(aircraft?.app_permissions?.postflight?.can_sign)}
                    handleSelectChange={(option): void => handleSelectChange(option, 'postflight')}
                    handleRemoveClick={(option): void => handleSelectChange(option, 'postflight')}
                  />
                )}
              </RadioWrapper>
            )}
          </ItemWrapper>
        </StyledGrid>
        {permissionMode === 'person' ? (
          <StyledGrid>
            <ItemWrapper span={editMode ? '2' : null} data-testid="AircraftIPadPermissions--MXCheck">
              <Label edit={editMode}>{formatMessage({ id: 'text.canAddMXCheck' })}</Label>
              {!editMode ? (
                <Text>{formattedDisplayText(aircraft?.app_permissions?.mx_check?.can_sign)}</Text>
              ) : (
                <TFSelectDropdown
                  options={PERSON_POSITIONS}
                  selected={addSpacesWhereAppropriate(aircraft?.app_permissions?.mx_check?.can_sign)}
                  handleSelectChange={(option): void => handleSelectChange(option, 'mx_check')}
                  handleRemoveClick={(option): void => handleSelectChange(option, 'mx_check')}
                />
              )}
            </ItemWrapper>
            <ItemWrapper span={editMode ? '2' : null} data-testid="AircraftIPadPermissions--CRS">
              <Label edit={editMode}>{formatMessage({ id: 'text.canAddCRS' })}</Label>
              {!editMode ? (
                <Text>{formattedDisplayText(aircraft?.app_permissions?.crs?.can_sign)}</Text>
              ) : (
                <TFSelectDropdown
                  options={PERSON_POSITIONS}
                  selected={addSpacesWhereAppropriate(aircraft?.app_permissions?.crs?.can_sign)}
                  handleSelectChange={(option): void => handleSelectChange(option, 'crs')}
                  handleRemoveClick={(option): void => handleSelectChange(option, 'crs')}
                />
              )}
            </ItemWrapper>
            <ItemWrapper span={editMode ? '2' : null} data-testid="AircraftIPadPermissions--Uplift">
              <Label edit={editMode}>{formatMessage({ id: 'text.canAddIssue' })}</Label>
              {!editMode ? (
                <Text>{formattedDisplayText(aircraft?.app_permissions?.issue?.can_sign)}</Text>
              ) : (
                <TFSelectDropdown
                  options={PERSON_POSITIONS}
                  selected={addSpacesWhereAppropriate(aircraft?.app_permissions?.issue?.can_sign)}
                  handleSelectChange={(option): void => handleSelectChange(option, 'issue')}
                  handleRemoveClick={(option): void => handleSelectChange(option, 'issue')}
                />
              )}
            </ItemWrapper>
            <ItemWrapper span={editMode ? '2' : null} data-testid="AircraftIPadPermissions--Issue">
              <Label edit={editMode}>{formatMessage({ id: 'text.canAddUplift' })}</Label>
              {!editMode ? (
                <Text>{formattedDisplayText(aircraft?.app_permissions?.uplift?.can_sign)}</Text>
              ) : (
                <TFSelectDropdown
                  options={PERSON_POSITIONS}
                  selected={addSpacesWhereAppropriate(aircraft?.app_permissions?.uplift?.can_sign)}
                  handleSelectChange={(option): void => handleSelectChange(option, 'uplift')}
                  handleRemoveClick={(option): void => handleSelectChange(option, 'uplift')}
                />
              )}
            </ItemWrapper>
          </StyledGrid>
        ) : null}
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button height="32px" onClick={handleLocalSaveClick}>
                {formatMessage({ id: 'text.save' })}
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              {formatMessage({ id: 'text.cancel' })}
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftIPadPermissions;
