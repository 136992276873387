import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { setDefaultFRAT } from '../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';

const useSetFRATDefault = (): UseMutationResult<void, Error, { id: string }, unknown> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: { id: string }) => setDefaultFRAT(payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['FRAT', data.operator_id] });
      dispatch(
        addToast({
          payload: {
            title: 'FRAT Updated',
            message: 'FRAT set as default successfully',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
  });
};

export default useSetFRATDefault;
