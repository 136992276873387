import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { deleteFRATQuestion } from '../../services/api';

interface DeleteFRATQuestionProps {
  handleDeleteQuestionOnSuccess: () => void;
  handleDeleteQuestionOnError: (err: Error) => void;
}

const useDeleteFRATQuestion = ({
  handleDeleteQuestionOnSuccess,
  handleDeleteQuestionOnError,
}: DeleteFRATQuestionProps): UseMutationResult<unknown, Error, { id: string }, unknown> => {
  const deleteQuestion = useMutation({
    mutationFn: (payload: { id: string }) => {
      return deleteFRATQuestion(payload);
    },
    onSuccess: () => {
      handleDeleteQuestionOnSuccess();
    },
    onError: (err: Error) => handleDeleteQuestionOnError(err),
  });

  return deleteQuestion;
};

export default useDeleteFRATQuestion;
