import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { updateFRATQuestion } from '../../services/api';
import { FRATOption, FRATQuestion } from '../../models/frats';

interface UpdateFRATQuestionProps {
  handleUpdateQuestionOnSuccess: (data: FRATQuestion) => void;
  handleUpdateQuestionOnError: (err: Error) => void;
}

const useUpdateFRATQuestion = ({
  handleUpdateQuestionOnSuccess,
  handleUpdateQuestionOnError,
}: UpdateFRATQuestionProps): UseMutationResult<
FRATQuestion,
Error,
{ question: string; flight_risk_assessment_options_attributes: FRATOption[] },
unknown
> => {
  const updateQuestion = useMutation({
    mutationFn: (payload: {
      question: string;
      flight_risk_assessment_options_attributes: FRATOption[];
    }) => {
      return updateFRATQuestion(payload);
    },
    onSuccess: (data) => {
      handleUpdateQuestionOnSuccess(data);
    },
    onError: (err: Error) => handleUpdateQuestionOnError(err),
  });

  return updateQuestion;
};

export default useUpdateFRATQuestion;
