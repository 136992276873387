import { Badge, Button, Table } from '@arcflight/tf-component-library';
import React, { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useTable, useSortBy, usePagination } from 'react-table';
import styled from 'styled-components';
import { Trip } from '../../models/trips';
import arrowIcon from '../../assets/icon-arrow.svg';
import plusIcon from '../../assets/plus.svg';
import FlightDrawer, { SectionHeader } from '../FlightDrawer/FlightDrawer';
import { changeDrawerContent, changeDrawerMode, setDrawerId } from '../../models/drawer';
import { AircraftAuthenticationWrapper } from '../_utils/AuthenticationWrapper';
import { AircraftPermission, AircraftResource } from '../../models/aircraft';
import useGetAppSetting from '../../utils/useGetAppSetting';
import useGetOperatorSetting from '../../utils/useGetOperatorSetting';

interface TripSectorsProps {
  trip: Trip;
}

const ContentWrapper = styled.div``;

const StyledSpan = styled.span`
  text-transform: capitalize;
  display: flex;
  flex-shrink: 0;
  align-items: center;
`;

const BadgeWrapper = styled.div`
  margin-right: 8px;
`;

const ArrowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SectionWrapper = styled.div`
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TripSectors: React.FC<TripSectorsProps> = ({ trip }) => {
  const dispatch = useDispatch();
  const { id: aircraftId } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();
  const sectorNameOverride = useGetOperatorSetting('sector_name', aircraftId) || formatMessage({ id: 'text.sector' });
  const sectorsNameOverride =
    useGetOperatorSetting('sector_name_plural', aircraftId) || formatMessage({ id: 'text.sectors' });

  const handleSectorClick = (e): void => {
    const { id } = e.original;
    dispatch(
      changeDrawerContent({
        payload: { content: <FlightDrawer />, backButton: true },
      }),
    );
    dispatch(setDrawerId({ payload: id }));
  };

  const handleAddSector = (): void => {
    dispatch(
      changeDrawerContent({
        payload: { content: <FlightDrawer trip={trip} />, backButton: true },
      }),
    );
    dispatch(changeDrawerMode({ payload: 'add' }));
  };

  const displayFRAT = useGetAppSetting('frat_enabled', aircraftId);

  const data = useMemo(() => trip?.flights, [trip]);
  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
        Cell: ({ value }): ReactNode => {
          let color = 'green';
          if (value === 'partial' || value === 'in_progress') color = 'amber';
          if (value === 'planned') color = 'grey';
          return value ? (
            <StyledSpan>
              <BadgeWrapper>
                <Badge color={color} />
              </BadgeWrapper>
              {value.replace(/_/g, ' ')}
            </StyledSpan>
          ) : (
            '-'
          );
        },
      },
      {
        Header: 'No.',
        width: 60,
        Cell: ({ row }): number => {
          return row.index + 1;
        },
      },
      {
        Header: 'From',
        accessor: 'departure_airport',
        width: 100,
        Cell: ({ value }): string => value || '-',
      },
      {
        Header: 'To',
        accessor: 'arrival_airport',
        width: 100,
        Cell: ({ value }): string => value || '-',
      },
      {
        width: 50,
        id: 'arrow',
        Cell: (): ReactNode => (
          <ArrowWrapper>
            <img src={arrowIcon} alt="arrow icon" />
          </ArrowWrapper>
        ),
      },
    ],
    [],
  );

  if (displayFRAT) {
    columns.splice(2, 0, {
      Header: 'FRAT',
      accessor: 'flight_risk_assessment_result',
      width: 100,
      Cell: ({ value }): string => {
        if (!value) {
          return '-';
        }
        return `${value?.percentage}%`;
      },
    });
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: [] },
    },
    useSortBy,
    usePagination,
  );
  return (
    <SectionWrapper data-testid="TripDrawer-TripSectors">
      <ButtonWrapper>
        <SectionHeader reducedBottomMargin marginLeft="20px" data-testid="TripSectors-Header">
          {sectorsNameOverride}
        </SectionHeader>
        {trip?.id ? (
          <AircraftAuthenticationWrapper
            aircraftId={trip?.aircraft_id}
            requiredResource={AircraftResource.TRIP}
            requiredPermissionLevel={AircraftPermission.CREATE}
          >
            <Button height="24px" onClick={handleAddSector}>
              <img src={plusIcon} alt="plus icon" />{' '}
              {formatMessage({ id: 'form.button.addSector' }, { sector: sectorNameOverride.toLowerCase() })}
            </Button>
          </AircraftAuthenticationWrapper>
        ) : null}
      </ButtonWrapper>
      <ContentWrapper>
        <Table tableInstance={tableInstance} handleRowClick={handleSectorClick} />
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default TripSectors;
