import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import TFTag from '../../components/TFTag/TFTag';
import useGetOperatorSetting from '../../utils/useGetOperatorSetting';
import estDueTimeOfAircraft from '../../utils/aircraftEstDueTime';
import servers from '../../utils/servers';
import ToolTip from '../../components/NewTooltip/NewTooltip';
import styles from './AircraftInfoSection.module.less';

const AircraftInfoSection = ({ aircraft, currentAirport }) => {
  const { formatMessage } = useIntl();
  let estDueTime = 0;
  if (aircraft) {
    estDueTime = estDueTimeOfAircraft(aircraft.estimated_next_due_date);
  }

  const lastFlownInSeconds = moment().diff(moment(aircraft.last_flown));
  const lastFlown = aircraft.last_flown !== null ? moment.duration(lastFlownInSeconds).humanize() : '-';

  let estimatedDueDisplay = `0 ${formatMessage({ id: 'text.days' })}`;
  if (estDueTime === '<1') estimatedDueDisplay = `<1 ${formatMessage({ id: 'text.day' })}`;
  if (estDueTime >= 1)
    estimatedDueDisplay = `${estDueTime} ${
      estDueTime === 1 ? formatMessage({ id: 'text.day' }) : formatMessage({ id: 'text.days' })
    }`;

  const approvalReleaseMode = useGetOperatorSetting('approval_release_mode', aircraft.id);
  const approvalReleaseName = useGetOperatorSetting('approval_release_name', aircraft.id);
  const crsShortName = useGetOperatorSetting('crs_short_name', aircraft.id);
  const showAircraftManufacturer = useGetOperatorSetting('show_aircraft_manufacturer', aircraft.id);

  let releaseText = crsShortName;
  if (['after_approval_release_required', 'after_each_approval_release_required'].includes(approvalReleaseMode)) {
    releaseText = approvalReleaseName;
  }

  return (
    <div className={styles.aircraftWrapper}>
      <div className={styles.currentStatus} data-testid="AircraftInfoSection--Title">
        {formatMessage({ id: 'title.currentStatus' })}
      </div>
      <div
        className={styles.aircraftImg}
        style={{
          backgroundImage: `url(${servers.api}${aircraft.aircraft_type.aircraft_image_url})`,
        }}
        alt={aircraft.registration}
        data-test="aircraftImage"
        data-testid="AircraftInfoSection--AircraftImage"
      />
      <div className={styles.aircraftModel} data-testid="AircraftInfoSection--ModelType">
        {aircraft.aircraft_type.model}
        {showAircraftManufacturer && (
          <span>
            {' '}- {aircraft.aircraft_type.manufacturer}
          </span>
        )}
      </div>
      <div className={styles.aircraftStatusLocation}>
        <Row className={styles.aircraftStatus}>
          <Col xs={14}>
            <div
              className={styles.tfTagWrapper}
              data-test="aircraftStatus"
              data-testid="AircraftInfoSection--FlightStatus"
            >
              <div className={styles.infoLabel}>{formatMessage({ id: 'title.statusCaps' })}</div>
              {(aircraft.maintenance_status === 'serviceable' || aircraft.maintenance_status === 'ok') && (
                <TFTag colour="green">{formatMessage({ id: 'status.serviceable' })}</TFTag>
              )}
              {aircraft.maintenance_status === 'critical' && (
                <TFTag colour="amber">{formatMessage({ id: 'status.inTolerance' })}</TFTag>
              )}
              {(aircraft.maintenance_status === 'unserviceable' || aircraft.maintenance_status === 'overdue') &&
                aircraft.maintenance_status_reason === 'missing_crs' && (
                  <TFTag color="red" wrap margin="0 10px 0 0">
                    {formatMessage({ id: 'status.unserviceable_missing_crs' }, { releaseText })}
                  </TFTag>
                )}
              {(aircraft.maintenance_status === 'unserviceable' || aircraft.maintenance_status === 'overdue') &&
                aircraft.maintenance_status_reason !== 'missing_crs' && (
                  <TFTag color="red">{formatMessage({ id: 'status.unserviceable' })}</TFTag>
                )}
              {aircraft.maintenance_status === 'unknown' && (
                <div className={styles.tooltipWrapper}>
                  <TFTag color="grey">{formatMessage({ id: 'status.unknown' })}</TFTag>
                  <ToolTip
                    noMargin
                    text="To make the aircraft serviceable, at least one open
                    maintenance item must be based on calendar days and at least one based on flight time"
                  />
                </div>
              )}
            </div>
          </Col>
          <Col xs={10}>
            <div data-test="aircraftEstDue" data-testid="AircraftInfoSection--EstimatedDue">
              <div className={styles.infoLabel}>{formatMessage({ id: 'title.estimatedDueCaps' })}</div>
              <TFTag>{estimatedDueDisplay}</TFTag>
            </div>
          </Col>
        </Row>
        {false && (
          <Row className={styles.infoMiddleRow}>
            <Col xs={14} data-testid="AircraftInfoSection--FuelOnBoard">
              <div className={styles.infoLabel}>{formatMessage({ id: 'text.fuelOnBoard' })}</div>
              <div>
                {aircraft.fuel_on_board || '-'} {aircraft.fuel_on_board ? aircraft.fuel_unit : null}
              </div>
            </Col>
            <Col xs={10}>
              <div data-test="aircraftLastFlight" data-testid="AircraftInfoSection--LastFlown">
                <div className={styles.infoLabel}>{formatMessage({ id: 'title.lastFlownCaps' })}</div>
                <div>
                  <span className={styles.lastFlown}>
                    {lastFlown}
                    {aircraft.last_flown !== null ? <span className={styles.lowercase}> ago</span> : null}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <div className={styles.aircraftLocation}>
          <div data-test="aircraftLocation" data-testid="AircraftInfoSection--Location">
            {aircraft.flight_status === 'in_flight' && (
              <>
                <div>{formatMessage({ id: 'status.inFlight' })}</div>
                <div>
                  <span data-test="aircraftLocationInFlight">
                    {aircraft.departure_airport?.ident && aircraft.destination_airport?.ident
                      ? `${aircraft.departure_airport?.ident} -
                                    ${aircraft.destination_airport?.ident}`
                      : ''}
                  </span>
                </div>
              </>
            )}
            {aircraft.flight_status === 'on_ground' && (
              <>
                <div className={styles.infoLabel}>{formatMessage({ id: 'status.onGround' })}</div>
                <div>
                  <span data-test="aircraftLocationGround">
                    {Object.keys(currentAirport).length !== 0
                      ? `${currentAirport.municipality || currentAirport.name || '-'} (${
                          currentAirport.gps_code || currentAirport?.ident
                        }), ${currentAirport.country}`
                      : '-'}
                  </span>
                </div>
              </>
            )}
            {aircraft.flight_status === 'unknown' && currentAirport && (
              <>
                <div className={styles.infoLabel}>{formatMessage({ id: 'status.lastKnownLocation' })}</div>
                <div>
                  <span data-test="aircraftLocationUnknown">
                    {Object.keys(currentAirport).length !== 0
                      ? `Last known was{' '}
                      ${currentAirport.municipality || currentAirport.name} (${
                          currentAirport.gps_code || currentAirport?.ident
                        }), ${currentAirport.country}`
                      : '-'}
                  </span>
                </div>
              </>
            )}
            {aircraft.flight_status === 'unknown' && !currentAirport && (
              <>
                <div className={styles.infoLabel}>{formatMessage({ id: 'status.location' })}</div>
                <div>
                  <span data-test="aircraftLocationUnknown">{formatMessage({ id: 'status.unknown' })}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AircraftInfoSection.propTypes = {
  aircraft: PropTypes.object.isRequired,
  currentAirport: PropTypes.object,
};

AircraftInfoSection.defaultProps = {
  currentAirport: {},
};

export default AircraftInfoSection;
