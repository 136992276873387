import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Accordion, Button, Table } from '@arcflight/tf-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useSortBy, usePagination } from 'react-table';
import { useParams } from 'react-router-dom';
import { DashboardState } from '../../models';
import cabinIcon from '../../assets/icon-card-cabin-log.svg';
import { Aircraft, AircraftResource, BaseAircraft } from '../../models/aircraft';
import { DataValue } from '../../utils/updateLocalDataObject';
import { fetchAircraftSublocationGroupLists } from '../../models/cabinIssues/action';
import EmptyState from '../EmptyState/EmptyState';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import AuthDropdownMenu from '../AuthDropdownMenu/AuthDropdownMenu';
import {
  AccordionWrapper,
  ButtonWrapper,
  ItemWrapper,
  Label,
  SaveWrapper,
  StyledGrid,
  Text,
} from './AircraftAdvancedSettings';
import AircraftSettingsCard from './AircraftSettingsCard';
import ShowHideToggle from './ShowHideToggle';
import CabinIssueLocationDrawer from './CabinIssueLocationDrawer';

interface AircraftCabinLogProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: DataValue; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
}

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.08px;
  color: #181818;
  margin: 0 16px;
  padding-top: 16px;
  margin-bottom: 16px;
`;

const TableWrapper = styled.div`
  padding: 0 16px 16px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
`;

const Divider = styled.div`
  margin: 16px;
  background-color: rgba(36, 45, 65, 0.05);
  height: 1px;
  width: -webkit-fill-available;
`;

const AircraftCabinLog: React.FC<AircraftCabinLogProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [lists, setLists] = useState([]);

  const {
    userSettings: {
      details: { operators },
    },
    cabinIssues: { cabinIssueSubLocations },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const cabinIssueTitle = operators.find((operator) => operator.id === aircraft?.operator_id)?.operator_setting
    ?.cabin_issue_name_singular;

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    handleSaveClick();
    setEditMode(false);
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  const handleToggleChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { enabled: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleEditLocation = (): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerContent({ payload: { content: <CabinIssueLocationDrawer /> } }));
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const toggleDrawer = (mode: string, value: string): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerContent({ payload: { content: <CabinIssueLocationDrawer /> } }));
    dispatch(changeDrawerMode({ payload: mode }));
    dispatch(setDrawerId({ payload: value }));
  };

  const handleRowClick = (value): void => {
    const cabinIssueId = value?.original?.id;
    if (id) {
      dispatch(changeDrawerVisibility({ payload: true }));
      dispatch(changeDrawerContent({ payload: { content: <CabinIssueLocationDrawer /> } }));
      dispatch(setDrawerId({ payload: cabinIssueId }));
    }
  };

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        width: 60,
        Header: 'Name',
        Cell: ({ value }) => {
          return value;
        },
      },
      {
        accessor: 'aircraft_sub_location_group_list_items_count',
        width: 60,
        Header: 'Sub Locations',
        Cell: ({ value }) => {
          return value;
        },
      },
      {
        Header: '',
        accessor: 'id',
        width: 60,
        Cell: ({ value }) => {
          return (
            <AuthDropdownMenu
              viewCallback={(): void => toggleDrawer('view', value)}
              editCallback={(): void => toggleDrawer('edit', value)}
              resource={AircraftResource.AIRCRAFT}
              forTable
              aircraftId={id}
              options={{ read: true, update: true, delete: false }}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const data = useMemo(() => {
    return lists;
  }, [lists]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    if (aircraft?.id) {
      dispatch(fetchAircraftSublocationGroupLists({ payload: { aircraftId: aircraft.id } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aircraft?.id]);

  useEffect(() => {
    if (cabinIssueSubLocations) setLists(cabinIssueSubLocations);
  }, [cabinIssueSubLocations]);

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={cabinIcon}
            title={cabinIssueTitle}
            buttonText={`Edit ${cabinIssueTitle}`}
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
          />
        }
      >
        <Title>{cabinIssueTitle}</Title>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>{`${cabinIssueTitle} enabled`}</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.cabin_log?.enabled ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.cabin_log?.enabled}
                handleChange={(value: boolean): void => handleToggleChange(value, 'cabin_log')}
              />
            )}
          </ItemWrapper>
        </StyledGrid>
        <Divider />
        <TitleWrapper>
          <Title>Cabin groups</Title>
          {editMode && data?.length ? (
            <Button height="24px" primary={false} onClick={handleEditLocation}>
              + Edit cabin group
            </Button>
          ) : null}
        </TitleWrapper>
        <TableWrapper>
          {data?.length ? (
            <Table tableInstance={tableInstance} handleRowClick={handleRowClick} />
          ) : (
            <EmptyState height="auto" image={cabinIcon} text="No cabin groups" />
          )}
        </TableWrapper>
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button height="32px" onClick={handleLocalSaveClick}>
                Save
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              Cancel
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftCabinLog;
