import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { renameFRATCategory } from '../../services/api';
import { FRATQuestionCategory } from '../../models/frats';

interface UpdateFRATCategoryProps {
  handeUpdateFRATCategoryOnSuccess: (data: FRATQuestionCategory) => void;
  handeUpdateFRATCategoryOnError: (err: Error) => void;
}

const useUpdateFRATCategory = ({
  handeUpdateFRATCategoryOnSuccess,
  handeUpdateFRATCategoryOnError,
}: UpdateFRATCategoryProps): UseMutationResult<
FRATQuestionCategory,
Error,
{ id: string; flight_risk_assessment_question_category: { category: string } },
unknown
> => {
  const renameCategory = useMutation({
    mutationFn: (payload: { id: string; flight_risk_assessment_question_category: { category: string } }) => {
      return renameFRATCategory(payload);
    },
    onSuccess: (data) => {
      handeUpdateFRATCategoryOnSuccess(data);
    },
    onError: (err: Error) => handeUpdateFRATCategoryOnError(err),
  });

  return renameCategory;
};

export default useUpdateFRATCategory;
